/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ClientTypeEnum from './ClientTypeEnum';
import ConnectorPolicyEnum from './ConnectorPolicyEnum';

/**
 * The ExtendedObjectStorageConnectorOut model module.
 * @module model/ExtendedObjectStorageConnectorOut
 * @version 1.0.0
 */
class ExtendedObjectStorageConnectorOut {
    /**
     * Constructs a new <code>ExtendedObjectStorageConnectorOut</code>.
     * @alias module:model/ExtendedObjectStorageConnectorOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param organizationId {String} 
     * @param name {String} 
     * @param policy {module:model/ConnectorPolicyEnum} 
     * @param bucketName {String} 
     * @param clientType {module:model/ClientTypeEnum} 
     */
    constructor(createdAt, updatedAt, id, organizationId, name, policy, bucketName, clientType) { 
        
        ExtendedObjectStorageConnectorOut.initialize(this, createdAt, updatedAt, id, organizationId, name, policy, bucketName, clientType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, organizationId, name, policy, bucketName, clientType) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['organization_id'] = organizationId;
        obj['name'] = name;
        obj['policy'] = policy;
        obj['bucket_name'] = bucketName;
        obj['client_type'] = clientType;
    }

    /**
     * Constructs a <code>ExtendedObjectStorageConnectorOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedObjectStorageConnectorOut} obj Optional instance to populate.
     * @return {module:model/ExtendedObjectStorageConnectorOut} The populated <code>ExtendedObjectStorageConnectorOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedObjectStorageConnectorOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('organization_id')) {
                obj['organization_id'] = ApiClient.convertToType(data['organization_id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('policy')) {
                obj['policy'] = ConnectorPolicyEnum.constructFromObject(data['policy']);
            }
            if (data.hasOwnProperty('bucket_name')) {
                obj['bucket_name'] = ApiClient.convertToType(data['bucket_name'], 'String');
            }
            if (data.hasOwnProperty('client_type')) {
                obj['client_type'] = ClientTypeEnum.constructFromObject(data['client_type']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedObjectStorageConnectorOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedObjectStorageConnectorOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedObjectStorageConnectorOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['organization_id'] && !(typeof data['organization_id'] === 'string' || data['organization_id'] instanceof String)) {
            throw new Error("Expected the field `organization_id` to be a primitive type in the JSON string but got " + data['organization_id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['bucket_name'] && !(typeof data['bucket_name'] === 'string' || data['bucket_name'] instanceof String)) {
            throw new Error("Expected the field `bucket_name` to be a primitive type in the JSON string but got " + data['bucket_name']);
        }

        return true;
    }


}

ExtendedObjectStorageConnectorOut.RequiredProperties = ["created_at", "updated_at", "id", "organization_id", "name", "policy", "bucket_name", "client_type"];

/**
 * @member {Date} created_at
 */
ExtendedObjectStorageConnectorOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedObjectStorageConnectorOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedObjectStorageConnectorOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedObjectStorageConnectorOut.prototype['id'] = undefined;

/**
 * @member {String} organization_id
 */
ExtendedObjectStorageConnectorOut.prototype['organization_id'] = undefined;

/**
 * @member {String} name
 */
ExtendedObjectStorageConnectorOut.prototype['name'] = undefined;

/**
 * @member {module:model/ConnectorPolicyEnum} policy
 */
ExtendedObjectStorageConnectorOut.prototype['policy'] = undefined;

/**
 * @member {String} bucket_name
 */
ExtendedObjectStorageConnectorOut.prototype['bucket_name'] = undefined;

/**
 * @member {module:model/ClientTypeEnum} client_type
 */
ExtendedObjectStorageConnectorOut.prototype['client_type'] = undefined;






export default ExtendedObjectStorageConnectorOut;

