/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorRole from './CollaboratorRole';
import ContributorRole from './ContributorRole';
import DatalakePermissionnerRole from './DatalakePermissionnerRole';
import DeploymentPermissionnerRole from './DeploymentPermissionnerRole';
import ModelPermissionnerRole from './ModelPermissionnerRole';
import WorkerRole from './WorkerRole';

/**
 * The CollaboratorIn model module.
 * @module model/CollaboratorIn
 * @version 1.0.0
 */
class CollaboratorIn {
    /**
     * Constructs a new <code>CollaboratorIn</code>.
     * @alias module:model/CollaboratorIn
     * @param userId {String} 
     */
    constructor(userId) { 
        
        CollaboratorIn.initialize(this, userId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, userId) { 
        obj['user_id'] = userId;
    }

    /**
     * Constructs a <code>CollaboratorIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CollaboratorIn} obj Optional instance to populate.
     * @return {module:model/CollaboratorIn} The populated <code>CollaboratorIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CollaboratorIn();

            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'String');
            }
            if (data.hasOwnProperty('assigned_role')) {
                obj['assigned_role'] = CollaboratorRole.constructFromObject(data['assigned_role']);
            }
            if (data.hasOwnProperty('default_datalake_permissionner_role')) {
                obj['default_datalake_permissionner_role'] = DatalakePermissionnerRole.constructFromObject(data['default_datalake_permissionner_role']);
            }
            if (data.hasOwnProperty('default_contributor_role')) {
                obj['default_contributor_role'] = ContributorRole.constructFromObject(data['default_contributor_role']);
            }
            if (data.hasOwnProperty('default_worker_role')) {
                obj['default_worker_role'] = WorkerRole.constructFromObject(data['default_worker_role']);
            }
            if (data.hasOwnProperty('default_model_permissionner_role')) {
                obj['default_model_permissionner_role'] = ModelPermissionnerRole.constructFromObject(data['default_model_permissionner_role']);
            }
            if (data.hasOwnProperty('default_deployment_permissionner_role')) {
                obj['default_deployment_permissionner_role'] = DeploymentPermissionnerRole.constructFromObject(data['default_deployment_permissionner_role']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CollaboratorIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CollaboratorIn</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CollaboratorIn.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
            throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
        }

        return true;
    }


}

CollaboratorIn.RequiredProperties = ["user_id"];

/**
 * @member {String} user_id
 */
CollaboratorIn.prototype['user_id'] = undefined;

/**
 * @member {module:model/CollaboratorRole} assigned_role
 */
CollaboratorIn.prototype['assigned_role'] = undefined;

/**
 * @member {module:model/DatalakePermissionnerRole} default_datalake_permissionner_role
 */
CollaboratorIn.prototype['default_datalake_permissionner_role'] = undefined;

/**
 * @member {module:model/ContributorRole} default_contributor_role
 */
CollaboratorIn.prototype['default_contributor_role'] = undefined;

/**
 * @member {module:model/WorkerRole} default_worker_role
 */
CollaboratorIn.prototype['default_worker_role'] = undefined;

/**
 * @member {module:model/ModelPermissionnerRole} default_model_permissionner_role
 */
CollaboratorIn.prototype['default_model_permissionner_role'] = undefined;

/**
 * @member {module:model/DeploymentPermissionnerRole} default_deployment_permissionner_role
 */
CollaboratorIn.prototype['default_deployment_permissionner_role'] = undefined;






export default CollaboratorIn;

