/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class CollaboratorPermission.
* @enum {}
* @readonly
*/
export default class CollaboratorPermission {
    
        /**
         * value: "add_collaborator"
         * @const
         */
        "add_collaborator" = "add_collaborator";

    
        /**
         * value: "change_collaborator"
         * @const
         */
        "change_collaborator" = "change_collaborator";

    
        /**
         * value: "delete_collaborator"
         * @const
         */
        "delete_collaborator" = "delete_collaborator";

    
        /**
         * value: "view_collaborator"
         * @const
         */
        "view_collaborator" = "view_collaborator";

    
        /**
         * value: "add_objectstorageconnector"
         * @const
         */
        "add_objectstorageconnector" = "add_objectstorageconnector";

    
        /**
         * value: "change_objectstorageconnector"
         * @const
         */
        "change_objectstorageconnector" = "change_objectstorageconnector";

    
        /**
         * value: "delete_objectstorageconnector"
         * @const
         */
        "delete_objectstorageconnector" = "delete_objectstorageconnector";

    
        /**
         * value: "view_objectstorageconnector"
         * @const
         */
        "view_objectstorageconnector" = "view_objectstorageconnector";

    
        /**
         * value: "use_objectstoragecconnector"
         * @const
         */
        "use_objectstoragecconnector" = "use_objectstoragecconnector";

    
        /**
         * value: "view_subscription"
         * @const
         */
        "view_subscription" = "view_subscription";

    
        /**
         * value: "view_plan"
         * @const
         */
        "view_plan" = "view_plan";

    
        /**
         * value: "add_tag"
         * @const
         */
        "add_tag" = "add_tag";

    
        /**
         * value: "change_tag"
         * @const
         */
        "change_tag" = "change_tag";

    
        /**
         * value: "delete_tag"
         * @const
         */
        "delete_tag" = "delete_tag";

    
        /**
         * value: "view_tag"
         * @const
         */
        "view_tag" = "view_tag";

    
        /**
         * value: "change_organization"
         * @const
         */
        "change_organization" = "change_organization";

    
        /**
         * value: "view_organization"
         * @const
         */
        "view_organization" = "view_organization";

    
        /**
         * value: "add_pendinguser"
         * @const
         */
        "add_pendinguser" = "add_pendinguser";

    
        /**
         * value: "delete_pendinguser"
         * @const
         */
        "delete_pendinguser" = "delete_pendinguser";

    
        /**
         * value: "view_pendinguser"
         * @const
         */
        "view_pendinguser" = "view_pendinguser";

    
        /**
         * value: "add_datasource"
         * @const
         */
        "add_datasource" = "add_datasource";

    
        /**
         * value: "change_datasource"
         * @const
         */
        "change_datasource" = "change_datasource";

    
        /**
         * value: "delete_datasource"
         * @const
         */
        "delete_datasource" = "delete_datasource";

    
        /**
         * value: "view_datasource"
         * @const
         */
        "view_datasource" = "view_datasource";

    
        /**
         * value: "add_processing"
         * @const
         */
        "add_processing" = "add_processing";

    
        /**
         * value: "change_processing"
         * @const
         */
        "change_processing" = "change_processing";

    
        /**
         * value: "delete_processing"
         * @const
         */
        "delete_processing" = "delete_processing";

    
        /**
         * value: "view_processing"
         * @const
         */
        "view_processing" = "view_processing";

    
        /**
         * value: "add_datalake"
         * @const
         */
        "add_datalake" = "add_datalake";

    
        /**
         * value: "view_datalake"
         * @const
         */
        "view_datalake" = "view_datalake";

    
        /**
         * value: "add_project"
         * @const
         */
        "add_project" = "add_project";

    
        /**
         * value: "view_project"
         * @const
         */
        "view_project" = "view_project";

    
        /**
         * value: "add_dataset"
         * @const
         */
        "add_dataset" = "add_dataset";

    
        /**
         * value: "view_dataset"
         * @const
         */
        "view_dataset" = "view_dataset";

    
        /**
         * value: "add_deployment"
         * @const
         */
        "add_deployment" = "add_deployment";

    
        /**
         * value: "view_deployment"
         * @const
         */
        "view_deployment" = "view_deployment";

    
        /**
         * value: "add_model"
         * @const
         */
        "add_model" = "add_model";

    
        /**
         * value: "view_model"
         * @const
         */
        "view_model" = "view_model";

    
        /**
         * value: "view_job"
         * @const
         */
        "view_job" = "view_job";

    
        /**
         * value: "view_jobv2"
         * @const
         */
        "view_jobv2" = "view_jobv2";

    
        /**
         * value: "add_jobv2"
         * @const
         */
        "add_jobv2" = "add_jobv2";

    
        /**
         * value: "change_jobv2"
         * @const
         */
        "change_jobv2" = "change_jobv2";

    
        /**
         * value: "delete_jobv2"
         * @const
         */
        "delete_jobv2" = "delete_jobv2";

    
        /**
         * value: "add_externalservice"
         * @const
         */
        "add_externalservice" = "add_externalservice";

    
        /**
         * value: "change_externalservice"
         * @const
         */
        "change_externalservice" = "change_externalservice";

    
        /**
         * value: "delete_externalservice"
         * @const
         */
        "delete_externalservice" = "delete_externalservice";

    
        /**
         * value: "view_externalservice"
         * @const
         */
        "view_externalservice" = "view_externalservice";

    
        /**
         * value: "add_webhook"
         * @const
         */
        "add_webhook" = "add_webhook";

    
        /**
         * value: "change_webhook"
         * @const
         */
        "change_webhook" = "change_webhook";

    
        /**
         * value: "delete_webhook"
         * @const
         */
        "delete_webhook" = "delete_webhook";

    
        /**
         * value: "view_webhook"
         * @const
         */
        "view_webhook" = "view_webhook";

    
        /**
         * value: "add_cvatconnector"
         * @const
         */
        "add_cvatconnector" = "add_cvatconnector";

    
        /**
         * value: "view_cvatconnector"
         * @const
         */
        "view_cvatconnector" = "view_cvatconnector";

    
        /**
         * value: "change_cvatconnector"
         * @const
         */
        "change_cvatconnector" = "change_cvatconnector";

    
        /**
         * value: "delete_cvatconnector"
         * @const
         */
        "delete_cvatconnector" = "delete_cvatconnector";

    
        /**
         * value: "add_dockerconnector"
         * @const
         */
        "add_dockerconnector" = "add_dockerconnector";

    
        /**
         * value: "view_dockerconnector"
         * @const
         */
        "view_dockerconnector" = "view_dockerconnector";

    
        /**
         * value: "change_dockerconnector"
         * @const
         */
        "change_dockerconnector" = "change_dockerconnector";

    
        /**
         * value: "delete_dockerconnector"
         * @const
         */
        "delete_dockerconnector" = "delete_dockerconnector";

    
        /**
         * value: "add_computeconnector"
         * @const
         */
        "add_computeconnector" = "add_computeconnector";

    
        /**
         * value: "view_computeconnector"
         * @const
         */
        "view_computeconnector" = "view_computeconnector";

    
        /**
         * value: "change_computeconnector"
         * @const
         */
        "change_computeconnector" = "change_computeconnector";

    
        /**
         * value: "delete_computeconnector"
         * @const
         */
        "delete_computeconnector" = "delete_computeconnector";

    

    /**
    * Returns a <code>CollaboratorPermission</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/CollaboratorPermission} The enum <code>CollaboratorPermission</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

