/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DockerClientTypeEnum from './DockerClientTypeEnum';

/**
 * The DockerConnectorOut model module.
 * @module model/DockerConnectorOut
 * @version 1.0.0
 */
class DockerConnectorOut {
    /**
     * Constructs a new <code>DockerConnectorOut</code>.
     * @alias module:model/DockerConnectorOut
     * @param id {String} 
     * @param name {String} 
     * @param registryUrl {String} 
     * @param clientType {module:model/DockerClientTypeEnum} 
     */
    constructor(id, name, registryUrl, clientType) { 
        
        DockerConnectorOut.initialize(this, id, name, registryUrl, clientType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, registryUrl, clientType) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['registry_url'] = registryUrl;
        obj['client_type'] = clientType;
    }

    /**
     * Constructs a <code>DockerConnectorOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DockerConnectorOut} obj Optional instance to populate.
     * @return {module:model/DockerConnectorOut} The populated <code>DockerConnectorOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DockerConnectorOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('registry_url')) {
                obj['registry_url'] = ApiClient.convertToType(data['registry_url'], 'String');
            }
            if (data.hasOwnProperty('client_type')) {
                obj['client_type'] = DockerClientTypeEnum.constructFromObject(data['client_type']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DockerConnectorOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DockerConnectorOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DockerConnectorOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['registry_url'] && !(typeof data['registry_url'] === 'string' || data['registry_url'] instanceof String)) {
            throw new Error("Expected the field `registry_url` to be a primitive type in the JSON string but got " + data['registry_url']);
        }

        return true;
    }


}

DockerConnectorOut.RequiredProperties = ["id", "name", "registry_url", "client_type"];

/**
 * @member {String} id
 */
DockerConnectorOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
DockerConnectorOut.prototype['name'] = undefined;

/**
 * @member {String} registry_url
 */
DockerConnectorOut.prototype['registry_url'] = undefined;

/**
 * @member {module:model/DockerClientTypeEnum} client_type
 */
DockerConnectorOut.prototype['client_type'] = undefined;






export default DockerConnectorOut;

