/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ExternalServiceType from './ExternalServiceType';

/**
 * The ExtendedExternalServiceOut model module.
 * @module model/ExtendedExternalServiceOut
 * @version 1.0.0
 */
class ExtendedExternalServiceOut {
    /**
     * Constructs a new <code>ExtendedExternalServiceOut</code>.
     * @alias module:model/ExtendedExternalServiceOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param type {module:model/ExternalServiceType} 
     * @param host {String} 
     * @param _private {Boolean} 
     */
    constructor(createdAt, updatedAt, id, type, host, _private) { 
        
        ExtendedExternalServiceOut.initialize(this, createdAt, updatedAt, id, type, host, _private);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, type, host, _private) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['type'] = type;
        obj['host'] = host;
        obj['private'] = _private;
    }

    /**
     * Constructs a <code>ExtendedExternalServiceOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedExternalServiceOut} obj Optional instance to populate.
     * @return {module:model/ExtendedExternalServiceOut} The populated <code>ExtendedExternalServiceOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedExternalServiceOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ExternalServiceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('host')) {
                obj['host'] = ApiClient.convertToType(data['host'], 'String');
            }
            if (data.hasOwnProperty('private')) {
                obj['private'] = ApiClient.convertToType(data['private'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedExternalServiceOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedExternalServiceOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedExternalServiceOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['host'] && !(typeof data['host'] === 'string' || data['host'] instanceof String)) {
            throw new Error("Expected the field `host` to be a primitive type in the JSON string but got " + data['host']);
        }

        return true;
    }


}

ExtendedExternalServiceOut.RequiredProperties = ["created_at", "updated_at", "id", "type", "host", "private"];

/**
 * @member {Date} created_at
 */
ExtendedExternalServiceOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedExternalServiceOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedExternalServiceOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedExternalServiceOut.prototype['id'] = undefined;

/**
 * @member {module:model/ExternalServiceType} type
 */
ExtendedExternalServiceOut.prototype['type'] = undefined;

/**
 * @member {String} host
 */
ExtendedExternalServiceOut.prototype['host'] = undefined;

/**
 * @member {Boolean} private
 */
ExtendedExternalServiceOut.prototype['private'] = undefined;






export default ExtendedExternalServiceOut;

