/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorPermissionOut from './CollaboratorPermissionOut';
import CollaboratorRole from './CollaboratorRole';
import ContributorRole from './ContributorRole';
import DatalakePermissionnerRole from './DatalakePermissionnerRole';
import DeploymentPermissionnerRole from './DeploymentPermissionnerRole';
import ModelPermissionnerRole from './ModelPermissionnerRole';
import WorkerRole from './WorkerRole';

/**
 * The ExtendedCollaboratorOut model module.
 * @module model/ExtendedCollaboratorOut
 * @version 1.0.0
 */
class ExtendedCollaboratorOut {
    /**
     * Constructs a new <code>ExtendedCollaboratorOut</code>.
     * @alias module:model/ExtendedCollaboratorOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param isActive {Boolean} 
     * @param role {module:model/CollaboratorRole} 
     * @param userId {String} 
     * @param username {String} 
     * @param email {String} 
     * @param permissions {Array.<module:model/CollaboratorPermissionOut>} 
     */
    constructor(createdAt, updatedAt, id, isActive, role, userId, username, email, permissions) { 
        
        ExtendedCollaboratorOut.initialize(this, createdAt, updatedAt, id, isActive, role, userId, username, email, permissions);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, isActive, role, userId, username, email, permissions) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['is_active'] = isActive;
        obj['role'] = role;
        obj['user_id'] = userId;
        obj['username'] = username;
        obj['email'] = email;
        obj['permissions'] = permissions;
    }

    /**
     * Constructs a <code>ExtendedCollaboratorOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedCollaboratorOut} obj Optional instance to populate.
     * @return {module:model/ExtendedCollaboratorOut} The populated <code>ExtendedCollaboratorOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedCollaboratorOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('is_active')) {
                obj['is_active'] = ApiClient.convertToType(data['is_active'], 'Boolean');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = CollaboratorRole.constructFromObject(data['role']);
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('picture')) {
                obj['picture'] = ApiClient.convertToType(data['picture'], 'String');
            }
            if (data.hasOwnProperty('picture_url')) {
                obj['picture_url'] = ApiClient.convertToType(data['picture_url'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('permissions')) {
                obj['permissions'] = ApiClient.convertToType(data['permissions'], [CollaboratorPermissionOut]);
            }
            if (data.hasOwnProperty('default_datalake_permissionner_role')) {
                obj['default_datalake_permissionner_role'] = DatalakePermissionnerRole.constructFromObject(data['default_datalake_permissionner_role']);
            }
            if (data.hasOwnProperty('default_contributor_role')) {
                obj['default_contributor_role'] = ContributorRole.constructFromObject(data['default_contributor_role']);
            }
            if (data.hasOwnProperty('default_worker_role')) {
                obj['default_worker_role'] = WorkerRole.constructFromObject(data['default_worker_role']);
            }
            if (data.hasOwnProperty('default_model_permissionner_role')) {
                obj['default_model_permissionner_role'] = ModelPermissionnerRole.constructFromObject(data['default_model_permissionner_role']);
            }
            if (data.hasOwnProperty('default_deployment_permissionner_role')) {
                obj['default_deployment_permissionner_role'] = DeploymentPermissionnerRole.constructFromObject(data['default_deployment_permissionner_role']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedCollaboratorOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedCollaboratorOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedCollaboratorOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
            throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
        }
        // ensure the json data is a string
        if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
            throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
        }
        // ensure the json data is a string
        if (data['picture'] && !(typeof data['picture'] === 'string' || data['picture'] instanceof String)) {
            throw new Error("Expected the field `picture` to be a primitive type in the JSON string but got " + data['picture']);
        }
        // ensure the json data is a string
        if (data['picture_url'] && !(typeof data['picture_url'] === 'string' || data['picture_url'] instanceof String)) {
            throw new Error("Expected the field `picture_url` to be a primitive type in the JSON string but got " + data['picture_url']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        if (data['permissions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['permissions'])) {
                throw new Error("Expected the field `permissions` to be an array in the JSON data but got " + data['permissions']);
            }
            // validate the optional field `permissions` (array)
            for (const item of data['permissions']) {
                CollaboratorPermissionOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExtendedCollaboratorOut.RequiredProperties = ["created_at", "updated_at", "id", "is_active", "role", "user_id", "username", "email", "permissions"];

/**
 * @member {Date} created_at
 */
ExtendedCollaboratorOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedCollaboratorOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedCollaboratorOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedCollaboratorOut.prototype['id'] = undefined;

/**
 * @member {Boolean} is_active
 */
ExtendedCollaboratorOut.prototype['is_active'] = undefined;

/**
 * @member {module:model/CollaboratorRole} role
 */
ExtendedCollaboratorOut.prototype['role'] = undefined;

/**
 * @member {String} user_id
 */
ExtendedCollaboratorOut.prototype['user_id'] = undefined;

/**
 * @member {String} username
 */
ExtendedCollaboratorOut.prototype['username'] = undefined;

/**
 * @member {String} picture
 */
ExtendedCollaboratorOut.prototype['picture'] = undefined;

/**
 * @member {String} picture_url
 */
ExtendedCollaboratorOut.prototype['picture_url'] = undefined;

/**
 * @member {String} email
 */
ExtendedCollaboratorOut.prototype['email'] = undefined;

/**
 * @member {Array.<module:model/CollaboratorPermissionOut>} permissions
 */
ExtendedCollaboratorOut.prototype['permissions'] = undefined;

/**
 * @member {module:model/DatalakePermissionnerRole} default_datalake_permissionner_role
 */
ExtendedCollaboratorOut.prototype['default_datalake_permissionner_role'] = undefined;

/**
 * @member {module:model/ContributorRole} default_contributor_role
 */
ExtendedCollaboratorOut.prototype['default_contributor_role'] = undefined;

/**
 * @member {module:model/WorkerRole} default_worker_role
 */
ExtendedCollaboratorOut.prototype['default_worker_role'] = undefined;

/**
 * @member {module:model/ModelPermissionnerRole} default_model_permissionner_role
 */
ExtendedCollaboratorOut.prototype['default_model_permissionner_role'] = undefined;

/**
 * @member {module:model/DeploymentPermissionnerRole} default_deployment_permissionner_role
 */
ExtendedCollaboratorOut.prototype['default_deployment_permissionner_role'] = undefined;






export default ExtendedCollaboratorOut;

