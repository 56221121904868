/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DatasetVersionProcessingConstraints from './DatasetVersionProcessingConstraints';
import PicturedUserOut from './PicturedUserOut';
import ProcessingType from './ProcessingType';

/**
 * The ProcessingOut model module.
 * @module model/ProcessingOut
 * @version 1.0.0
 */
class ProcessingOut {
    /**
     * Constructs a new <code>ProcessingOut</code>.
     * @alias module:model/ProcessingOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param dockerRegistry {String} 
     * @param dockerImage {String} 
     * @param dockerTag {String} 
     * @param dockerFlags {Array.<String>} 
     * @param name {String} 
     * @param description {String} 
     * @param thumbnailObjectName {String} 
     * @param id {String} 
     * @param type {module:model/ProcessingType} 
     * @param defaultParameters {Object} 
     * @param defaultCpu {Number} 
     * @param defaultGpu {Number} 
     * @param _private {Boolean} 
     * @param datasetVersionConstraints {module:model/DatasetVersionProcessingConstraints} 
     */
    constructor(createdAt, updatedAt, createdBy, dockerRegistry, dockerImage, dockerTag, dockerFlags, name, description, thumbnailObjectName, id, type, defaultParameters, defaultCpu, defaultGpu, _private, datasetVersionConstraints) { 
        
        ProcessingOut.initialize(this, createdAt, updatedAt, createdBy, dockerRegistry, dockerImage, dockerTag, dockerFlags, name, description, thumbnailObjectName, id, type, defaultParameters, defaultCpu, defaultGpu, _private, datasetVersionConstraints);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, dockerRegistry, dockerImage, dockerTag, dockerFlags, name, description, thumbnailObjectName, id, type, defaultParameters, defaultCpu, defaultGpu, _private, datasetVersionConstraints) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['docker_registry'] = dockerRegistry;
        obj['docker_image'] = dockerImage;
        obj['docker_tag'] = dockerTag;
        obj['docker_flags'] = dockerFlags;
        obj['name'] = name;
        obj['description'] = description;
        obj['thumbnail_object_name'] = thumbnailObjectName;
        obj['id'] = id;
        obj['type'] = type;
        obj['default_parameters'] = defaultParameters;
        obj['default_cpu'] = defaultCpu;
        obj['default_gpu'] = defaultGpu;
        obj['private'] = _private;
        obj['dataset_version_constraints'] = datasetVersionConstraints;
    }

    /**
     * Constructs a <code>ProcessingOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProcessingOut} obj Optional instance to populate.
     * @return {module:model/ProcessingOut} The populated <code>ProcessingOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProcessingOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('docker_registry')) {
                obj['docker_registry'] = ApiClient.convertToType(data['docker_registry'], 'String');
            }
            if (data.hasOwnProperty('docker_image')) {
                obj['docker_image'] = ApiClient.convertToType(data['docker_image'], 'String');
            }
            if (data.hasOwnProperty('docker_tag')) {
                obj['docker_tag'] = ApiClient.convertToType(data['docker_tag'], 'String');
            }
            if (data.hasOwnProperty('docker_flags')) {
                obj['docker_flags'] = ApiClient.convertToType(data['docker_flags'], ['String']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail_object_name')) {
                obj['thumbnail_object_name'] = ApiClient.convertToType(data['thumbnail_object_name'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ProcessingType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('default_parameters')) {
                obj['default_parameters'] = ApiClient.convertToType(data['default_parameters'], Object);
            }
            if (data.hasOwnProperty('default_cpu')) {
                obj['default_cpu'] = ApiClient.convertToType(data['default_cpu'], 'Number');
            }
            if (data.hasOwnProperty('default_gpu')) {
                obj['default_gpu'] = ApiClient.convertToType(data['default_gpu'], 'Number');
            }
            if (data.hasOwnProperty('private')) {
                obj['private'] = ApiClient.convertToType(data['private'], 'Boolean');
            }
            if (data.hasOwnProperty('dataset_version_constraints')) {
                obj['dataset_version_constraints'] = DatasetVersionProcessingConstraints.constructFromObject(data['dataset_version_constraints']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProcessingOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProcessingOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ProcessingOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['docker_registry'] && !(typeof data['docker_registry'] === 'string' || data['docker_registry'] instanceof String)) {
            throw new Error("Expected the field `docker_registry` to be a primitive type in the JSON string but got " + data['docker_registry']);
        }
        // ensure the json data is a string
        if (data['docker_image'] && !(typeof data['docker_image'] === 'string' || data['docker_image'] instanceof String)) {
            throw new Error("Expected the field `docker_image` to be a primitive type in the JSON string but got " + data['docker_image']);
        }
        // ensure the json data is a string
        if (data['docker_tag'] && !(typeof data['docker_tag'] === 'string' || data['docker_tag'] instanceof String)) {
            throw new Error("Expected the field `docker_tag` to be a primitive type in the JSON string but got " + data['docker_tag']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['docker_flags'])) {
            throw new Error("Expected the field `docker_flags` to be an array in the JSON data but got " + data['docker_flags']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['thumbnail_object_name'] && !(typeof data['thumbnail_object_name'] === 'string' || data['thumbnail_object_name'] instanceof String)) {
            throw new Error("Expected the field `thumbnail_object_name` to be a primitive type in the JSON string but got " + data['thumbnail_object_name']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // validate the optional field `dataset_version_constraints`
        if (data['dataset_version_constraints']) { // data not null
          DatasetVersionProcessingConstraints.validateJSON(data['dataset_version_constraints']);
        }

        return true;
    }


}

ProcessingOut.RequiredProperties = ["created_at", "updated_at", "created_by", "docker_registry", "docker_image", "docker_tag", "docker_flags", "name", "description", "thumbnail_object_name", "id", "type", "default_parameters", "default_cpu", "default_gpu", "private", "dataset_version_constraints"];

/**
 * @member {Date} created_at
 */
ProcessingOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ProcessingOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ProcessingOut.prototype['created_by'] = undefined;

/**
 * @member {String} docker_registry
 */
ProcessingOut.prototype['docker_registry'] = undefined;

/**
 * @member {String} docker_image
 */
ProcessingOut.prototype['docker_image'] = undefined;

/**
 * @member {String} docker_tag
 */
ProcessingOut.prototype['docker_tag'] = undefined;

/**
 * @member {Array.<String>} docker_flags
 */
ProcessingOut.prototype['docker_flags'] = undefined;

/**
 * @member {String} name
 */
ProcessingOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ProcessingOut.prototype['description'] = undefined;

/**
 * @member {String} thumbnail_object_name
 */
ProcessingOut.prototype['thumbnail_object_name'] = undefined;

/**
 * @member {String} id
 */
ProcessingOut.prototype['id'] = undefined;

/**
 * @member {module:model/ProcessingType} type
 */
ProcessingOut.prototype['type'] = undefined;

/**
 * @member {Object} default_parameters
 */
ProcessingOut.prototype['default_parameters'] = undefined;

/**
 * @member {Number} default_cpu
 */
ProcessingOut.prototype['default_cpu'] = undefined;

/**
 * @member {Number} default_gpu
 */
ProcessingOut.prototype['default_gpu'] = undefined;

/**
 * @member {Boolean} private
 */
ProcessingOut.prototype['private'] = undefined;

/**
 * @member {module:model/DatasetVersionProcessingConstraints} dataset_version_constraints
 */
ProcessingOut.prototype['dataset_version_constraints'] = undefined;






export default ProcessingOut;

