/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ExternalServiceType from './ExternalServiceType';

/**
 * The OptionalExternalServiceIn model module.
 * @module model/OptionalExternalServiceIn
 * @version 1.0.0
 */
class OptionalExternalServiceIn {
    /**
     * Constructs a new <code>OptionalExternalServiceIn</code>.
     * @alias module:model/OptionalExternalServiceIn
     */
    constructor() { 
        
        OptionalExternalServiceIn.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OptionalExternalServiceIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OptionalExternalServiceIn} obj Optional instance to populate.
     * @return {module:model/OptionalExternalServiceIn} The populated <code>OptionalExternalServiceIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OptionalExternalServiceIn();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ExternalServiceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('host')) {
                obj['host'] = ApiClient.convertToType(data['host'], 'String');
            }
            if (data.hasOwnProperty('private')) {
                obj['private'] = ApiClient.convertToType(data['private'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OptionalExternalServiceIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OptionalExternalServiceIn</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['host'] && !(typeof data['host'] === 'string' || data['host'] instanceof String)) {
            throw new Error("Expected the field `host` to be a primitive type in the JSON string but got " + data['host']);
        }

        return true;
    }


}



/**
 * @member {module:model/ExternalServiceType} type
 */
OptionalExternalServiceIn.prototype['type'] = undefined;

/**
 * @member {String} host
 */
OptionalExternalServiceIn.prototype['host'] = undefined;

/**
 * @member {Boolean} private
 */
OptionalExternalServiceIn.prototype['private'] = undefined;






export default OptionalExternalServiceIn;

