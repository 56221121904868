/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrganizationOut from './OrganizationOut';

/**
 * The MyOrganizationsOut model module.
 * @module model/MyOrganizationsOut
 * @version 1.0.0
 */
class MyOrganizationsOut {
    /**
     * Constructs a new <code>MyOrganizationsOut</code>.
     * @alias module:model/MyOrganizationsOut
     * @param mine {module:model/OrganizationOut} 
     * @param others {Array.<module:model/OrganizationOut>} 
     */
    constructor(mine, others) { 
        
        MyOrganizationsOut.initialize(this, mine, others);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, mine, others) { 
        obj['mine'] = mine;
        obj['others'] = others;
    }

    /**
     * Constructs a <code>MyOrganizationsOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MyOrganizationsOut} obj Optional instance to populate.
     * @return {module:model/MyOrganizationsOut} The populated <code>MyOrganizationsOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MyOrganizationsOut();

            if (data.hasOwnProperty('mine')) {
                obj['mine'] = OrganizationOut.constructFromObject(data['mine']);
            }
            if (data.hasOwnProperty('others')) {
                obj['others'] = ApiClient.convertToType(data['others'], [OrganizationOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MyOrganizationsOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MyOrganizationsOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MyOrganizationsOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `mine`
        if (data['mine']) { // data not null
          OrganizationOut.validateJSON(data['mine']);
        }
        if (data['others']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['others'])) {
                throw new Error("Expected the field `others` to be an array in the JSON data but got " + data['others']);
            }
            // validate the optional field `others` (array)
            for (const item of data['others']) {
                OrganizationOut.validateJSON(item);
            };
        }

        return true;
    }


}

MyOrganizationsOut.RequiredProperties = ["mine", "others"];

/**
 * @member {module:model/OrganizationOut} mine
 */
MyOrganizationsOut.prototype['mine'] = undefined;

/**
 * @member {Array.<module:model/OrganizationOut>} others
 */
MyOrganizationsOut.prototype['others'] = undefined;






export default MyOrganizationsOut;

