/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DatasetVersionProcessingJobOut model module.
 * @module model/DatasetVersionProcessingJobOut
 * @version 1.0.0
 */
class DatasetVersionProcessingJobOut {
    /**
     * Constructs a new <code>DatasetVersionProcessingJobOut</code>.
     * @alias module:model/DatasetVersionProcessingJobOut
     * @param processingId {String} 
     * @param parameters {Object} 
     * @param cpu {Number} 
     * @param gpu {Number} 
     * @param inputDatasetVersionId {String} 
     * @param outputDatasetVersionId {String} 
     * @param modelVersionId {String} 
     */
    constructor(processingId, parameters, cpu, gpu, inputDatasetVersionId, outputDatasetVersionId, modelVersionId) { 
        
        DatasetVersionProcessingJobOut.initialize(this, processingId, parameters, cpu, gpu, inputDatasetVersionId, outputDatasetVersionId, modelVersionId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, processingId, parameters, cpu, gpu, inputDatasetVersionId, outputDatasetVersionId, modelVersionId) { 
        obj['processing_id'] = processingId;
        obj['parameters'] = parameters;
        obj['cpu'] = cpu;
        obj['gpu'] = gpu;
        obj['input_dataset_version_id'] = inputDatasetVersionId;
        obj['output_dataset_version_id'] = outputDatasetVersionId;
        obj['model_version_id'] = modelVersionId;
    }

    /**
     * Constructs a <code>DatasetVersionProcessingJobOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DatasetVersionProcessingJobOut} obj Optional instance to populate.
     * @return {module:model/DatasetVersionProcessingJobOut} The populated <code>DatasetVersionProcessingJobOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DatasetVersionProcessingJobOut();

            if (data.hasOwnProperty('processing_id')) {
                obj['processing_id'] = ApiClient.convertToType(data['processing_id'], 'String');
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], Object);
            }
            if (data.hasOwnProperty('cpu')) {
                obj['cpu'] = ApiClient.convertToType(data['cpu'], 'Number');
            }
            if (data.hasOwnProperty('gpu')) {
                obj['gpu'] = ApiClient.convertToType(data['gpu'], 'Number');
            }
            if (data.hasOwnProperty('input_dataset_version_id')) {
                obj['input_dataset_version_id'] = ApiClient.convertToType(data['input_dataset_version_id'], 'String');
            }
            if (data.hasOwnProperty('output_dataset_version_id')) {
                obj['output_dataset_version_id'] = ApiClient.convertToType(data['output_dataset_version_id'], 'String');
            }
            if (data.hasOwnProperty('model_version_id')) {
                obj['model_version_id'] = ApiClient.convertToType(data['model_version_id'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DatasetVersionProcessingJobOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DatasetVersionProcessingJobOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DatasetVersionProcessingJobOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['processing_id'] && !(typeof data['processing_id'] === 'string' || data['processing_id'] instanceof String)) {
            throw new Error("Expected the field `processing_id` to be a primitive type in the JSON string but got " + data['processing_id']);
        }
        // ensure the json data is a string
        if (data['input_dataset_version_id'] && !(typeof data['input_dataset_version_id'] === 'string' || data['input_dataset_version_id'] instanceof String)) {
            throw new Error("Expected the field `input_dataset_version_id` to be a primitive type in the JSON string but got " + data['input_dataset_version_id']);
        }
        // ensure the json data is a string
        if (data['output_dataset_version_id'] && !(typeof data['output_dataset_version_id'] === 'string' || data['output_dataset_version_id'] instanceof String)) {
            throw new Error("Expected the field `output_dataset_version_id` to be a primitive type in the JSON string but got " + data['output_dataset_version_id']);
        }
        // ensure the json data is a string
        if (data['model_version_id'] && !(typeof data['model_version_id'] === 'string' || data['model_version_id'] instanceof String)) {
            throw new Error("Expected the field `model_version_id` to be a primitive type in the JSON string but got " + data['model_version_id']);
        }

        return true;
    }


}

DatasetVersionProcessingJobOut.RequiredProperties = ["processing_id", "parameters", "cpu", "gpu", "input_dataset_version_id", "output_dataset_version_id", "model_version_id"];

/**
 * @member {String} processing_id
 */
DatasetVersionProcessingJobOut.prototype['processing_id'] = undefined;

/**
 * @member {Object} parameters
 */
DatasetVersionProcessingJobOut.prototype['parameters'] = undefined;

/**
 * @member {Number} cpu
 */
DatasetVersionProcessingJobOut.prototype['cpu'] = undefined;

/**
 * @member {Number} gpu
 */
DatasetVersionProcessingJobOut.prototype['gpu'] = undefined;

/**
 * @member {String} input_dataset_version_id
 */
DatasetVersionProcessingJobOut.prototype['input_dataset_version_id'] = undefined;

/**
 * @member {String} output_dataset_version_id
 */
DatasetVersionProcessingJobOut.prototype['output_dataset_version_id'] = undefined;

/**
 * @member {String} model_version_id
 */
DatasetVersionProcessingJobOut.prototype['model_version_id'] = undefined;






export default DatasetVersionProcessingJobOut;

