/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FrameworkEnum from './FrameworkEnum';
import InferenceType from './InferenceType';

/**
 * The DeploymentModelVersionOut model module.
 * @module model/DeploymentModelVersionOut
 * @version 1.0.0
 */
class DeploymentModelVersionOut {
    /**
     * Constructs a new <code>DeploymentModelVersionOut</code>.
     * @alias module:model/DeploymentModelVersionOut
     * @param id {String} 
     * @param originId {String} 
     * @param originName {String} 
     * @param name {String} 
     * @param type {module:model/InferenceType} 
     * @param framework {module:model/FrameworkEnum} 
     * @param version {Number} 
     */
    constructor(id, originId, originName, name, type, framework, version) { 
        
        DeploymentModelVersionOut.initialize(this, id, originId, originName, name, type, framework, version);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, originId, originName, name, type, framework, version) { 
        obj['id'] = id;
        obj['origin_id'] = originId;
        obj['origin_name'] = originName;
        obj['name'] = name;
        obj['type'] = type;
        obj['framework'] = framework;
        obj['version'] = version;
    }

    /**
     * Constructs a <code>DeploymentModelVersionOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeploymentModelVersionOut} obj Optional instance to populate.
     * @return {module:model/DeploymentModelVersionOut} The populated <code>DeploymentModelVersionOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeploymentModelVersionOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('origin_id')) {
                obj['origin_id'] = ApiClient.convertToType(data['origin_id'], 'String');
            }
            if (data.hasOwnProperty('origin_name')) {
                obj['origin_name'] = ApiClient.convertToType(data['origin_name'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = InferenceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('framework')) {
                obj['framework'] = FrameworkEnum.constructFromObject(data['framework']);
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DeploymentModelVersionOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeploymentModelVersionOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DeploymentModelVersionOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['origin_id'] && !(typeof data['origin_id'] === 'string' || data['origin_id'] instanceof String)) {
            throw new Error("Expected the field `origin_id` to be a primitive type in the JSON string but got " + data['origin_id']);
        }
        // ensure the json data is a string
        if (data['origin_name'] && !(typeof data['origin_name'] === 'string' || data['origin_name'] instanceof String)) {
            throw new Error("Expected the field `origin_name` to be a primitive type in the JSON string but got " + data['origin_name']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }

        return true;
    }


}

DeploymentModelVersionOut.RequiredProperties = ["id", "origin_id", "origin_name", "name", "type", "framework", "version"];

/**
 * @member {String} id
 */
DeploymentModelVersionOut.prototype['id'] = undefined;

/**
 * @member {String} origin_id
 */
DeploymentModelVersionOut.prototype['origin_id'] = undefined;

/**
 * @member {String} origin_name
 */
DeploymentModelVersionOut.prototype['origin_name'] = undefined;

/**
 * @member {String} name
 */
DeploymentModelVersionOut.prototype['name'] = undefined;

/**
 * @member {module:model/InferenceType} type
 */
DeploymentModelVersionOut.prototype['type'] = undefined;

/**
 * @member {module:model/FrameworkEnum} framework
 */
DeploymentModelVersionOut.prototype['framework'] = undefined;

/**
 * @member {Number} version
 */
DeploymentModelVersionOut.prototype['version'] = undefined;






export default DeploymentModelVersionOut;

