/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorRole from './CollaboratorRole';
import ContributorRole from './ContributorRole';
import DatalakePermissionnerRole from './DatalakePermissionnerRole';
import DeploymentPermissionnerRole from './DeploymentPermissionnerRole';
import ModelPermissionnerRole from './ModelPermissionnerRole';
import WorkerRole from './WorkerRole';

/**
 * The PendingUserOut model module.
 * @module model/PendingUserOut
 * @version 1.0.0
 */
class PendingUserOut {
    /**
     * Constructs a new <code>PendingUserOut</code>.
     * @alias module:model/PendingUserOut
     * @param id {String} 
     * @param organizationId {String} 
     * @param email {String} 
     * @param assignedRole {module:model/CollaboratorRole} 
     * @param expirationDate {Date} 
     */
    constructor(id, organizationId, email, assignedRole, expirationDate) { 
        
        PendingUserOut.initialize(this, id, organizationId, email, assignedRole, expirationDate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, organizationId, email, assignedRole, expirationDate) { 
        obj['id'] = id;
        obj['organization_id'] = organizationId;
        obj['email'] = email;
        obj['assigned_role'] = assignedRole;
        obj['expiration_date'] = expirationDate;
    }

    /**
     * Constructs a <code>PendingUserOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PendingUserOut} obj Optional instance to populate.
     * @return {module:model/PendingUserOut} The populated <code>PendingUserOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PendingUserOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('organization_id')) {
                obj['organization_id'] = ApiClient.convertToType(data['organization_id'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('assigned_role')) {
                obj['assigned_role'] = CollaboratorRole.constructFromObject(data['assigned_role']);
            }
            if (data.hasOwnProperty('expiration_date')) {
                obj['expiration_date'] = ApiClient.convertToType(data['expiration_date'], 'Date');
            }
            if (data.hasOwnProperty('default_datalake_permissionner_role')) {
                obj['default_datalake_permissionner_role'] = DatalakePermissionnerRole.constructFromObject(data['default_datalake_permissionner_role']);
            }
            if (data.hasOwnProperty('default_contributor_role')) {
                obj['default_contributor_role'] = ContributorRole.constructFromObject(data['default_contributor_role']);
            }
            if (data.hasOwnProperty('default_worker_role')) {
                obj['default_worker_role'] = WorkerRole.constructFromObject(data['default_worker_role']);
            }
            if (data.hasOwnProperty('default_model_permissionner_role')) {
                obj['default_model_permissionner_role'] = ModelPermissionnerRole.constructFromObject(data['default_model_permissionner_role']);
            }
            if (data.hasOwnProperty('default_deployment_permissionner_role')) {
                obj['default_deployment_permissionner_role'] = DeploymentPermissionnerRole.constructFromObject(data['default_deployment_permissionner_role']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PendingUserOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PendingUserOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of PendingUserOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['organization_id'] && !(typeof data['organization_id'] === 'string' || data['organization_id'] instanceof String)) {
            throw new Error("Expected the field `organization_id` to be a primitive type in the JSON string but got " + data['organization_id']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }

        return true;
    }


}

PendingUserOut.RequiredProperties = ["id", "organization_id", "email", "assigned_role", "expiration_date"];

/**
 * @member {String} id
 */
PendingUserOut.prototype['id'] = undefined;

/**
 * @member {String} organization_id
 */
PendingUserOut.prototype['organization_id'] = undefined;

/**
 * @member {String} email
 */
PendingUserOut.prototype['email'] = undefined;

/**
 * @member {module:model/CollaboratorRole} assigned_role
 */
PendingUserOut.prototype['assigned_role'] = undefined;

/**
 * @member {Date} expiration_date
 */
PendingUserOut.prototype['expiration_date'] = undefined;

/**
 * @member {module:model/DatalakePermissionnerRole} default_datalake_permissionner_role
 */
PendingUserOut.prototype['default_datalake_permissionner_role'] = undefined;

/**
 * @member {module:model/ContributorRole} default_contributor_role
 */
PendingUserOut.prototype['default_contributor_role'] = undefined;

/**
 * @member {module:model/WorkerRole} default_worker_role
 */
PendingUserOut.prototype['default_worker_role'] = undefined;

/**
 * @member {module:model/ModelPermissionnerRole} default_model_permissionner_role
 */
PendingUserOut.prototype['default_model_permissionner_role'] = undefined;

/**
 * @member {module:model/DeploymentPermissionnerRole} default_deployment_permissionner_role
 */
PendingUserOut.prototype['default_deployment_permissionner_role'] = undefined;






export default PendingUserOut;

