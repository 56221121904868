/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ModelPermissionnerRole.
* @enum {}
* @readonly
*/
export default class ModelPermissionnerRole {
    
        /**
         * value: "reader"
         * @const
         */
        "reader" = "reader";

    
        /**
         * value: "user"
         * @const
         */
        "user" = "user";

    
        /**
         * value: "admin"
         * @const
         */
        "admin" = "admin";

    

    /**
    * Returns a <code>ModelPermissionnerRole</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ModelPermissionnerRole} The enum <code>ModelPermissionnerRole</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

