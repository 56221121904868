/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ProcessingType.
* @enum {}
* @readonly
*/
export default class ProcessingType {
    
        /**
         * value: "PRE_ANNOTATION"
         * @const
         */
        "PRE_ANNOTATION" = "PRE_ANNOTATION";

    
        /**
         * value: "DATA_AUGMENTATION"
         * @const
         */
        "DATA_AUGMENTATION" = "DATA_AUGMENTATION";

    
        /**
         * value: "DATASET_VERSION_CREATION"
         * @const
         */
        "DATASET_VERSION_CREATION" = "DATASET_VERSION_CREATION";

    
        /**
         * value: "AUTO_TAGGING"
         * @const
         */
        "AUTO_TAGGING" = "AUTO_TAGGING";

    
        /**
         * value: "AUTO_ANNOTATION"
         * @const
         */
        "AUTO_ANNOTATION" = "AUTO_ANNOTATION";

    

    /**
    * Returns a <code>ProcessingType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ProcessingType} The enum <code>ProcessingType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

