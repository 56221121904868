/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DatasetVersionConstraint model module.
 * @module model/DatasetVersionConstraint
 * @version 1.0.0
 */
class DatasetVersionConstraint {
    /**
     * Constructs a new <code>DatasetVersionConstraint</code>.
     * @alias module:model/DatasetVersionConstraint
     * @param alias {String} 
     */
    constructor(alias) { 
        
        DatasetVersionConstraint.initialize(this, alias);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, alias) { 
        obj['alias'] = alias;
    }

    /**
     * Constructs a <code>DatasetVersionConstraint</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DatasetVersionConstraint} obj Optional instance to populate.
     * @return {module:model/DatasetVersionConstraint} The populated <code>DatasetVersionConstraint</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DatasetVersionConstraint();

            if (data.hasOwnProperty('alias')) {
                obj['alias'] = ApiClient.convertToType(data['alias'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DatasetVersionConstraint</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DatasetVersionConstraint</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DatasetVersionConstraint.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['alias'] && !(typeof data['alias'] === 'string' || data['alias'] instanceof String)) {
            throw new Error("Expected the field `alias` to be a primitive type in the JSON string but got " + data['alias']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }

        return true;
    }


}

DatasetVersionConstraint.RequiredProperties = ["alias"];

/**
 * @member {String} alias
 */
DatasetVersionConstraint.prototype['alias'] = undefined;

/**
 * @member {String} description
 */
DatasetVersionConstraint.prototype['description'] = undefined;






export default DatasetVersionConstraint;

