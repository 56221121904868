/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ExternalServiceType.
* @enum {}
* @readonly
*/
export default class ExternalServiceType {
    
        /**
         * value: "ORACLE"
         * @const
         */
        "ORACLE" = "ORACLE";

    
        /**
         * value: "SERVING"
         * @const
         */
        "SERVING" = "SERVING";

    
        /**
         * value: "KONPAKU"
         * @const
         */
        "KONPAKU" = "KONPAKU";

    
        /**
         * value: "VISUAL_SEARCH"
         * @const
         */
        "VISUAL_SEARCH" = "VISUAL_SEARCH";

    
        /**
         * value: "TORAKKA"
         * @const
         */
        "TORAKKA" = "TORAKKA";

    
        /**
         * value: "KONOHA"
         * @const
         */
        "KONOHA" = "KONOHA";

    

    /**
    * Returns a <code>ExternalServiceType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ExternalServiceType} The enum <code>ExternalServiceType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

