/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorOut from './CollaboratorOut';

/**
 * The PagedCollaboratorOut model module.
 * @module model/PagedCollaboratorOut
 * @version 1.0.0
 */
class PagedCollaboratorOut {
    /**
     * Constructs a new <code>PagedCollaboratorOut</code>.
     * @alias module:model/PagedCollaboratorOut
     * @param items {Array.<module:model/CollaboratorOut>} 
     * @param count {Number} 
     */
    constructor(items, count) { 
        
        PagedCollaboratorOut.initialize(this, items, count);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, items, count) { 
        obj['items'] = items;
        obj['count'] = count;
    }

    /**
     * Constructs a <code>PagedCollaboratorOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PagedCollaboratorOut} obj Optional instance to populate.
     * @return {module:model/PagedCollaboratorOut} The populated <code>PagedCollaboratorOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PagedCollaboratorOut();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [CollaboratorOut]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PagedCollaboratorOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PagedCollaboratorOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of PagedCollaboratorOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['items']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['items'])) {
                throw new Error("Expected the field `items` to be an array in the JSON data but got " + data['items']);
            }
            // validate the optional field `items` (array)
            for (const item of data['items']) {
                CollaboratorOut.validateJSON(item);
            };
        }

        return true;
    }


}

PagedCollaboratorOut.RequiredProperties = ["items", "count"];

/**
 * @member {Array.<module:model/CollaboratorOut>} items
 */
PagedCollaboratorOut.prototype['items'] = undefined;

/**
 * @member {Number} count
 */
PagedCollaboratorOut.prototype['count'] = undefined;






export default PagedCollaboratorOut;

