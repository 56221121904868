/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectStorageConnectorOut from './ObjectStorageConnectorOut';

/**
 * The ExtendedDatalakeOut model module.
 * @module model/ExtendedDatalakeOut
 * @version 1.0.0
 */
class ExtendedDatalakeOut {
    /**
     * Constructs a new <code>ExtendedDatalakeOut</code>.
     * @alias module:model/ExtendedDatalakeOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param name {String} 
     * @param connectorId {String} 
     * @param connector {module:model/ObjectStorageConnectorOut} 
     * @param size {Number} 
     */
    constructor(createdAt, updatedAt, id, name, connectorId, connector, size) { 
        
        ExtendedDatalakeOut.initialize(this, createdAt, updatedAt, id, name, connectorId, connector, size);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, name, connectorId, connector, size) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['name'] = name;
        obj['connector_id'] = connectorId;
        obj['connector'] = connector;
        obj['size'] = size;
    }

    /**
     * Constructs a <code>ExtendedDatalakeOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedDatalakeOut} obj Optional instance to populate.
     * @return {module:model/ExtendedDatalakeOut} The populated <code>ExtendedDatalakeOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedDatalakeOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('connector_id')) {
                obj['connector_id'] = ApiClient.convertToType(data['connector_id'], 'String');
            }
            if (data.hasOwnProperty('connector_client_type')) {
                obj['connector_client_type'] = ApiClient.convertToType(data['connector_client_type'], 'String');
            }
            if (data.hasOwnProperty('connector')) {
                obj['connector'] = ObjectStorageConnectorOut.constructFromObject(data['connector']);
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedDatalakeOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedDatalakeOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedDatalakeOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['connector_id'] && !(typeof data['connector_id'] === 'string' || data['connector_id'] instanceof String)) {
            throw new Error("Expected the field `connector_id` to be a primitive type in the JSON string but got " + data['connector_id']);
        }
        // ensure the json data is a string
        if (data['connector_client_type'] && !(typeof data['connector_client_type'] === 'string' || data['connector_client_type'] instanceof String)) {
            throw new Error("Expected the field `connector_client_type` to be a primitive type in the JSON string but got " + data['connector_client_type']);
        }
        // validate the optional field `connector`
        if (data['connector']) { // data not null
          ObjectStorageConnectorOut.validateJSON(data['connector']);
        }

        return true;
    }


}

ExtendedDatalakeOut.RequiredProperties = ["created_at", "updated_at", "id", "name", "connector_id", "connector", "size"];

/**
 * @member {Date} created_at
 */
ExtendedDatalakeOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedDatalakeOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedDatalakeOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedDatalakeOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ExtendedDatalakeOut.prototype['name'] = undefined;

/**
 * @member {String} connector_id
 */
ExtendedDatalakeOut.prototype['connector_id'] = undefined;

/**
 * @member {String} connector_client_type
 */
ExtendedDatalakeOut.prototype['connector_client_type'] = undefined;

/**
 * @member {module:model/ObjectStorageConnectorOut} connector
 */
ExtendedDatalakeOut.prototype['connector'] = undefined;

/**
 * @member {Number} size
 */
ExtendedDatalakeOut.prototype['size'] = undefined;






export default ExtendedDatalakeOut;

