/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DeploymentJobOut model module.
 * @module model/DeploymentJobOut
 * @version 1.0.0
 */
class DeploymentJobOut {
    /**
     * Constructs a new <code>DeploymentJobOut</code>.
     * @alias module:model/DeploymentJobOut
     * @param deploymentId {String} 
     */
    constructor(deploymentId) { 
        
        DeploymentJobOut.initialize(this, deploymentId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, deploymentId) { 
        obj['deployment_id'] = deploymentId;
    }

    /**
     * Constructs a <code>DeploymentJobOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeploymentJobOut} obj Optional instance to populate.
     * @return {module:model/DeploymentJobOut} The populated <code>DeploymentJobOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeploymentJobOut();

            if (data.hasOwnProperty('deployment_id')) {
                obj['deployment_id'] = ApiClient.convertToType(data['deployment_id'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DeploymentJobOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeploymentJobOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DeploymentJobOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['deployment_id'] && !(typeof data['deployment_id'] === 'string' || data['deployment_id'] instanceof String)) {
            throw new Error("Expected the field `deployment_id` to be a primitive type in the JSON string but got " + data['deployment_id']);
        }

        return true;
    }


}

DeploymentJobOut.RequiredProperties = ["deployment_id"];

/**
 * @member {String} deployment_id
 */
DeploymentJobOut.prototype['deployment_id'] = undefined;






export default DeploymentJobOut;

