/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ActiveCampaignOut from '../model/ActiveCampaignOut';
import CollaboratorIn from '../model/CollaboratorIn';
import CollaboratorOut from '../model/CollaboratorOut';
import CollaboratorPermissionsUpdateIn from '../model/CollaboratorPermissionsUpdateIn';
import DataSourceIn from '../model/DataSourceIn';
import DatalakeIn from '../model/DatalakeIn';
import DatalakeOut from '../model/DatalakeOut';
import DatasetIn from '../model/DatasetIn';
import DatasetVersionOut from '../model/DatasetVersionOut';
import DefaultDatalakeUpdateIn from '../model/DefaultDatalakeUpdateIn';
import DeploymentIn from '../model/DeploymentIn';
import DeploymentOut from '../model/DeploymentOut';
import ExperimentOut from '../model/ExperimentOut';
import ExtendedCollaboratorOut from '../model/ExtendedCollaboratorOut';
import ExtendedDataSourceOut from '../model/ExtendedDataSourceOut';
import ExtendedDatasetOut from '../model/ExtendedDatasetOut';
import ExtendedExternalServiceOut from '../model/ExtendedExternalServiceOut';
import ExtendedJobOut from '../model/ExtendedJobOut';
import ExtendedModelOut from '../model/ExtendedModelOut';
import ExtendedModelVersionOut from '../model/ExtendedModelVersionOut';
import ExtendedObjectStorageConnectorOut from '../model/ExtendedObjectStorageConnectorOut';
import ExtendedOrganizationOut from '../model/ExtendedOrganizationOut';
import ExtendedProjectOut from '../model/ExtendedProjectOut';
import ExternalServiceIn from '../model/ExternalServiceIn';
import FrameworkEnum from '../model/FrameworkEnum';
import GenerateObjectNameIn from '../model/GenerateObjectNameIn';
import InferenceType from '../model/InferenceType';
import JobType from '../model/JobType';
import KonohaOut from '../model/KonohaOut';
import ModelIn from '../model/ModelIn';
import MyOrganizationsOut from '../model/MyOrganizationsOut';
import MyobokuRegisterIn from '../model/MyobokuRegisterIn';
import MyobokuRegisterOut from '../model/MyobokuRegisterOut';
import MyobokuSetupIn from '../model/MyobokuSetupIn';
import ObjectNameIn from '../model/ObjectNameIn';
import ObjectStorageConnectorIn from '../model/ObjectStorageConnectorIn';
import OptionalExternalServiceIn from '../model/OptionalExternalServiceIn';
import OptionalOrganizationUpdateIn from '../model/OptionalOrganizationUpdateIn';
import OrganizationOut from '../model/OrganizationOut';
import OrganizationTagIn from '../model/OrganizationTagIn';
import OrganizationUpdateIn from '../model/OrganizationUpdateIn';
import PagedCollaboratorOut from '../model/PagedCollaboratorOut';
import PagedComputeConnectorOut from '../model/PagedComputeConnectorOut';
import PagedCvatConnectorOut from '../model/PagedCvatConnectorOut';
import PagedDataSourceOut from '../model/PagedDataSourceOut';
import PagedDatalakeOut from '../model/PagedDatalakeOut';
import PagedDatasetOut from '../model/PagedDatasetOut';
import PagedDeploymentOut from '../model/PagedDeploymentOut';
import PagedDockerConnectorOut from '../model/PagedDockerConnectorOut';
import PagedExtendedDatalakeOut from '../model/PagedExtendedDatalakeOut';
import PagedExtendedDeploymentOut from '../model/PagedExtendedDeploymentOut';
import PagedExtendedJobOut from '../model/PagedExtendedJobOut';
import PagedExtendedModelOut from '../model/PagedExtendedModelOut';
import PagedExtendedProjectOut from '../model/PagedExtendedProjectOut';
import PagedModelOut from '../model/PagedModelOut';
import PagedObjectStorageConnectorOut from '../model/PagedObjectStorageConnectorOut';
import PagedPendingUserOut from '../model/PagedPendingUserOut';
import PagedProcessingOut from '../model/PagedProcessingOut';
import PagedProjectOut from '../model/PagedProjectOut';
import PagedTagOut from '../model/PagedTagOut';
import PagedWebhookOut from '../model/PagedWebhookOut';
import PendingInvitationIn from '../model/PendingInvitationIn';
import PendingUserOut from '../model/PendingUserOut';
import ProcessingIn from '../model/ProcessingIn';
import ProcessingOut from '../model/ProcessingOut';
import ProjectIn from '../model/ProjectIn';
import TagBulkActionIn from '../model/TagBulkActionIn';
import TagIn from '../model/TagIn';
import TagOut from '../model/TagOut';
import TagTargetEnum from '../model/TagTargetEnum';
import TorakkaOut from '../model/TorakkaOut';
import WebhookIn from '../model/WebhookIn';
import WebhookOut from '../model/WebhookOut';

/**
* Organization service.
* @module api/OrganizationApi
* @version 1.0.0
*/
export default class OrganizationApi {

    /**
    * Constructs a new OrganizationApi. 
    * @alias module:api/OrganizationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     *  
     * @param {String} id 
     * @param {module:model/CollaboratorIn} collaboratorIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollaboratorOut} and HTTP response
     */
    addCollaboratorWithHttpInfo(id, collaboratorIn) {
      let postBody = collaboratorIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling addCollaborator");
      }
      // verify the required parameter 'collaboratorIn' is set
      if (collaboratorIn === undefined || collaboratorIn === null) {
        throw new Error("Missing the required parameter 'collaboratorIn' when calling addCollaborator");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CollaboratorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/collaborators', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/CollaboratorIn} collaboratorIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollaboratorOut}
     */
    addCollaborator(id, collaboratorIn) {
      return this.addCollaboratorWithHttpInfo(id, collaboratorIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    bulkAttachTagsOnOrganizationScopeWithHttpInfo(id, tagBulkActionIn) {
      let postBody = tagBulkActionIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling bulkAttachTagsOnOrganizationScope");
      }
      // verify the required parameter 'tagBulkActionIn' is set
      if (tagBulkActionIn === undefined || tagBulkActionIn === null) {
        throw new Error("Missing the required parameter 'tagBulkActionIn' when calling bulkAttachTagsOnOrganizationScope");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/organization/{id}/tags/attach', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    bulkAttachTagsOnOrganizationScope(id, tagBulkActionIn) {
      return this.bulkAttachTagsOnOrganizationScopeWithHttpInfo(id, tagBulkActionIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Bulk create Tag(s)
     * @param {String} id 
     * @param {Array.<module:model/OrganizationTagIn>} organizationTagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/TagOut>} and HTTP response
     */
    bulkCreateOrganizationTagsWithHttpInfo(id, organizationTagIn) {
      let postBody = organizationTagIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling bulkCreateOrganizationTags");
      }
      // verify the required parameter 'organizationTagIn' is set
      if (organizationTagIn === undefined || organizationTagIn === null) {
        throw new Error("Missing the required parameter 'organizationTagIn' when calling bulkCreateOrganizationTags");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [TagOut];
      return this.apiClient.callApi(
        '/api/organization/{id}/tags/bulk', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Bulk create Tag(s)
     * @param {String} id 
     * @param {Array.<module:model/OrganizationTagIn>} organizationTagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/TagOut>}
     */
    bulkCreateOrganizationTags(id, organizationTagIn) {
      return this.bulkCreateOrganizationTagsWithHttpInfo(id, organizationTagIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    bulkDetachTagsOnOrganizationScopeWithHttpInfo(id, tagBulkActionIn) {
      let postBody = tagBulkActionIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling bulkDetachTagsOnOrganizationScope");
      }
      // verify the required parameter 'tagBulkActionIn' is set
      if (tagBulkActionIn === undefined || tagBulkActionIn === null) {
        throw new Error("Missing the required parameter 'tagBulkActionIn' when calling bulkDetachTagsOnOrganizationScope");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/organization/{id}/tags/detach', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    bulkDetachTagsOnOrganizationScope(id, tagBulkActionIn) {
      return this.bulkDetachTagsOnOrganizationScopeWithHttpInfo(id, tagBulkActionIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/DatalakeIn} datalakeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatalakeOut} and HTTP response
     */
    createDatalakeWithHttpInfo(id, datalakeIn) {
      let postBody = datalakeIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createDatalake");
      }
      // verify the required parameter 'datalakeIn' is set
      if (datalakeIn === undefined || datalakeIn === null) {
        throw new Error("Missing the required parameter 'datalakeIn' when calling createDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DatalakeOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datalakes', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/DatalakeIn} datalakeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatalakeOut}
     */
    createDatalake(id, datalakeIn) {
      return this.createDatalakeWithHttpInfo(id, datalakeIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/DatasetIn} datasetIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDatasetOut} and HTTP response
     */
    createDatasetWithHttpInfo(id, datasetIn) {
      let postBody = datasetIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createDataset");
      }
      // verify the required parameter 'datasetIn' is set
      if (datasetIn === undefined || datasetIn === null) {
        throw new Error("Missing the required parameter 'datasetIn' when calling createDataset");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedDatasetOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/DatasetIn} datasetIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDatasetOut}
     */
    createDataset(id, datasetIn) {
      return this.createDatasetWithHttpInfo(id, datasetIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Model
     * @param {String} id 
     * @param {module:model/ModelIn} modelIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedModelOut} and HTTP response
     */
    createModelWithHttpInfo(id, modelIn) {
      let postBody = modelIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createModel");
      }
      // verify the required parameter 'modelIn' is set
      if (modelIn === undefined || modelIn === null) {
        throw new Error("Missing the required parameter 'modelIn' when calling createModel");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedModelOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/models', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create Model
     * @param {String} id 
     * @param {module:model/ModelIn} modelIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedModelOut}
     */
    createModel(id, modelIn) {
      return this.createModelWithHttpInfo(id, modelIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create DataSource(s)
     * @param {String} id 
     * @param {module:model/DataSourceIn} dataSourceIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDataSourceOut} and HTTP response
     */
    createOrganizationDatasourcesWithHttpInfo(id, dataSourceIn) {
      let postBody = dataSourceIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createOrganizationDatasources");
      }
      // verify the required parameter 'dataSourceIn' is set
      if (dataSourceIn === undefined || dataSourceIn === null) {
        throw new Error("Missing the required parameter 'dataSourceIn' when calling createOrganizationDatasources");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedDataSourceOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasources', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create DataSource(s)
     * @param {String} id 
     * @param {module:model/DataSourceIn} dataSourceIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDataSourceOut}
     */
    createOrganizationDatasources(id, dataSourceIn) {
      return this.createOrganizationDatasourcesWithHttpInfo(id, dataSourceIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create ObjectStorageConnector(s)
     * @param {String} id 
     * @param {module:model/ObjectStorageConnectorIn} objectStorageConnectorIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedObjectStorageConnectorOut} and HTTP response
     */
    createOrganizationObjectstorageconnectorsWithHttpInfo(id, objectStorageConnectorIn) {
      let postBody = objectStorageConnectorIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createOrganizationObjectstorageconnectors");
      }
      // verify the required parameter 'objectStorageConnectorIn' is set
      if (objectStorageConnectorIn === undefined || objectStorageConnectorIn === null) {
        throw new Error("Missing the required parameter 'objectStorageConnectorIn' when calling createOrganizationObjectstorageconnectors");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedObjectStorageConnectorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/objectstorageconnectors', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create ObjectStorageConnector(s)
     * @param {String} id 
     * @param {module:model/ObjectStorageConnectorIn} objectStorageConnectorIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedObjectStorageConnectorOut}
     */
    createOrganizationObjectstorageconnectors(id, objectStorageConnectorIn) {
      return this.createOrganizationObjectstorageconnectorsWithHttpInfo(id, objectStorageConnectorIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Processing(s)
     * @param {String} id 
     * @param {module:model/ProcessingIn} processingIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProcessingOut} and HTTP response
     */
    createOrganizationProcessingsWithHttpInfo(id, processingIn) {
      let postBody = processingIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createOrganizationProcessings");
      }
      // verify the required parameter 'processingIn' is set
      if (processingIn === undefined || processingIn === null) {
        throw new Error("Missing the required parameter 'processingIn' when calling createOrganizationProcessings");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProcessingOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/processings', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create Processing(s)
     * @param {String} id 
     * @param {module:model/ProcessingIn} processingIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProcessingOut}
     */
    createOrganizationProcessings(id, processingIn) {
      return this.createOrganizationProcessingsWithHttpInfo(id, processingIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Tag(s)
     * @param {String} id 
     * @param {module:model/OrganizationTagIn} organizationTagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagOut} and HTTP response
     */
    createOrganizationTagsWithHttpInfo(id, organizationTagIn) {
      let postBody = organizationTagIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createOrganizationTags");
      }
      // verify the required parameter 'organizationTagIn' is set
      if (organizationTagIn === undefined || organizationTagIn === null) {
        throw new Error("Missing the required parameter 'organizationTagIn' when calling createOrganizationTags");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = TagOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/tags', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create Tag(s)
     * @param {String} id 
     * @param {module:model/OrganizationTagIn} organizationTagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagOut}
     */
    createOrganizationTags(id, organizationTagIn) {
      return this.createOrganizationTagsWithHttpInfo(id, organizationTagIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Webhook(s)
     * @param {String} id 
     * @param {module:model/WebhookIn} webhookIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebhookOut} and HTTP response
     */
    createOrganizationWebhooksWithHttpInfo(id, webhookIn) {
      let postBody = webhookIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createOrganizationWebhooks");
      }
      // verify the required parameter 'webhookIn' is set
      if (webhookIn === undefined || webhookIn === null) {
        throw new Error("Missing the required parameter 'webhookIn' when calling createOrganizationWebhooks");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebhookOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/webhooks', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create Webhook(s)
     * @param {String} id 
     * @param {module:model/WebhookIn} webhookIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebhookOut}
     */
    createOrganizationWebhooks(id, webhookIn) {
      return this.createOrganizationWebhooksWithHttpInfo(id, webhookIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/ProjectIn} projectIn 
     * @param {Object} opts Optional parameters
     * @param {Boolean} [createContributors = false)] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedProjectOut} and HTTP response
     */
    createProjectWithHttpInfo(id, projectIn, opts) {
      opts = opts || {};
      let postBody = projectIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createProject");
      }
      // verify the required parameter 'projectIn' is set
      if (projectIn === undefined || projectIn === null) {
        throw new Error("Missing the required parameter 'projectIn' when calling createProject");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'create_contributors': opts['createContributors']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedProjectOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/projects', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/ProjectIn} projectIn 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.createContributors  (default to false)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedProjectOut}
     */
    createProject(id, projectIn, opts) {
      return this.createProjectWithHttpInfo(id, projectIn, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete Collaborator
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteCollaboratorWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteCollaborator");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/collaborator/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete Collaborator
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteCollaborator(id) {
      return this.deleteCollaboratorWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete ExternalService
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteExternalserviceWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteExternalservice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/external/service/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete ExternalService
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteExternalservice(id) {
      return this.deleteExternalserviceWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrganizationOut} and HTTP response
     */
    findOrganizationWithHttpInfo(name) {
      let postBody = null;
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling findOrganization");
      }

      let pathParams = {
      };
      let queryParams = {
        'name': name
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrganizationOut;
      return this.apiClient.callApi(
        '/api/organizations/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrganizationOut}
     */
    findOrganization(name) {
      return this.findOrganizationWithHttpInfo(name)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Collaborator
     * @param {String} id 
     * @param {String} username 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollaboratorOut} and HTTP response
     */
    findOrganizationCollaboratorWithHttpInfo(id, username) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationCollaborator");
      }
      // verify the required parameter 'username' is set
      if (username === undefined || username === null) {
        throw new Error("Missing the required parameter 'username' when calling findOrganizationCollaborator");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'username': username
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CollaboratorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/collaborators/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Collaborator
     * @param {String} id 
     * @param {String} username 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollaboratorOut}
     */
    findOrganizationCollaborator(id, username) {
      return this.findOrganizationCollaboratorWithHttpInfo(id, username)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Dataset
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [id2] 
     * @param {String} [name] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDatasetOut} and HTTP response
     */
    findOrganizationDatasetWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationDataset");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': opts['id2'],
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedDatasetOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasets/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Dataset
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.id2 
     * @param {String} opts.name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDatasetOut}
     */
    findOrganizationDataset(id, opts) {
      return this.findOrganizationDatasetWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find DatasetVersion
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatasetVersionOut} and HTTP response
     */
    findOrganizationDatasetversionWithHttpInfo(id, id2) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationDatasetversion");
      }
      // verify the required parameter 'id2' is set
      if (id2 === undefined || id2 === null) {
        throw new Error("Missing the required parameter 'id2' when calling findOrganizationDatasetversion");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': id2
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DatasetVersionOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasetversions/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find DatasetVersion
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatasetVersionOut}
     */
    findOrganizationDatasetversion(id, id2) {
      return this.findOrganizationDatasetversionWithHttpInfo(id, id2)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find DataSource
     * @param {String} id 
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDataSourceOut} and HTTP response
     */
    findOrganizationDatasourceWithHttpInfo(id, name) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationDatasource");
      }
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling findOrganizationDatasource");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': name
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedDataSourceOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasources/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find DataSource
     * @param {String} id 
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDataSourceOut}
     */
    findOrganizationDatasource(id, name) {
      return this.findOrganizationDatasourceWithHttpInfo(id, name)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Deployment
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [id2] 
     * @param {String} [name] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeploymentOut} and HTTP response
     */
    findOrganizationDeploymentWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationDeployment");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': opts['id2'],
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DeploymentOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/deployments/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Deployment
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.id2 
     * @param {String} opts.name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeploymentOut}
     */
    findOrganizationDeployment(id, opts) {
      return this.findOrganizationDeploymentWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Experiment
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExperimentOut} and HTTP response
     */
    findOrganizationExperimentWithHttpInfo(id, id2) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationExperiment");
      }
      // verify the required parameter 'id2' is set
      if (id2 === undefined || id2 === null) {
        throw new Error("Missing the required parameter 'id2' when calling findOrganizationExperiment");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': id2
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExperimentOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/experiments/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Experiment
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExperimentOut}
     */
    findOrganizationExperiment(id, id2) {
      return this.findOrganizationExperimentWithHttpInfo(id, id2)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find JobV2
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedJobOut} and HTTP response
     */
    findOrganizationJobv2WithHttpInfo(id, id2) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationJobv2");
      }
      // verify the required parameter 'id2' is set
      if (id2 === undefined || id2 === null) {
        throw new Error("Missing the required parameter 'id2' when calling findOrganizationJobv2");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': id2
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedJobOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/jobv2s/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find JobV2
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedJobOut}
     */
    findOrganizationJobv2(id, id2) {
      return this.findOrganizationJobv2WithHttpInfo(id, id2)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Model
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [id2] 
     * @param {String} [name] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedModelOut} and HTTP response
     */
    findOrganizationModelWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationModel");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': opts['id2'],
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedModelOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/models/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Model
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.id2 
     * @param {String} opts.name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedModelOut}
     */
    findOrganizationModel(id, opts) {
      return this.findOrganizationModelWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find ModelVersion
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedModelVersionOut} and HTTP response
     */
    findOrganizationModelversionWithHttpInfo(id, id2) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationModelversion");
      }
      // verify the required parameter 'id2' is set
      if (id2 === undefined || id2 === null) {
        throw new Error("Missing the required parameter 'id2' when calling findOrganizationModelversion");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': id2
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedModelVersionOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/modelversions/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find ModelVersion
     * @param {String} id 
     * @param {String} id2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedModelVersionOut}
     */
    findOrganizationModelversion(id, id2) {
      return this.findOrganizationModelversionWithHttpInfo(id, id2)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Processing
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {module:model/InferenceType} [type] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProcessingOut} and HTTP response
     */
    findOrganizationProcessingWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationProcessing");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'type': opts['type']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProcessingOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/processings/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Processing
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:model/InferenceType} opts.type 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProcessingOut}
     */
    findOrganizationProcessing(id, opts) {
      return this.findOrganizationProcessingWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Project
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [id2] 
     * @param {String} [name] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedProjectOut} and HTTP response
     */
    findOrganizationProjectWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationProject");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': opts['id2'],
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedProjectOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/projects/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Project
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.id2 
     * @param {String} opts.name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedProjectOut}
     */
    findOrganizationProject(id, opts) {
      return this.findOrganizationProjectWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Tag
     * @param {String} id 
     * @param {String} name 
     * @param {Object} opts Optional parameters
     * @param {module:model/TagTargetEnum} [targetType] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagOut} and HTTP response
     */
    findOrganizationTagWithHttpInfo(id, name, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findOrganizationTag");
      }
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling findOrganizationTag");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': name,
        'target_type': opts['targetType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TagOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/tags/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Tag
     * @param {String} id 
     * @param {String} name 
     * @param {Object} opts Optional parameters
     * @param {module:model/TagTargetEnum} opts.targetType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagOut}
     */
    findOrganizationTag(id, name, opts) {
      return this.findOrganizationTagWithHttpInfo(id, name, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {String} connectorId 
     * @param {module:model/GenerateObjectNameIn} generateObjectNameIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ObjectNameIn} and HTTP response
     */
    generateObjectNameWithHttpInfo(id, connectorId, generateObjectNameIn) {
      let postBody = generateObjectNameIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling generateObjectName");
      }
      // verify the required parameter 'connectorId' is set
      if (connectorId === undefined || connectorId === null) {
        throw new Error("Missing the required parameter 'connectorId' when calling generateObjectName");
      }
      // verify the required parameter 'generateObjectNameIn' is set
      if (generateObjectNameIn === undefined || generateObjectNameIn === null) {
        throw new Error("Missing the required parameter 'generateObjectNameIn' when calling generateObjectName");
      }

      let pathParams = {
        'id': id,
        'connector_id': connectorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ObjectNameIn;
      return this.apiClient.callApi(
        '/api/organization/{id}/connector/{connector_id}/generate_object_name', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {String} connectorId 
     * @param {module:model/GenerateObjectNameIn} generateObjectNameIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ObjectNameIn}
     */
    generateObjectName(id, connectorId, generateObjectNameIn) {
      return this.generateObjectNameWithHttpInfo(id, connectorId, generateObjectNameIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Collaborator
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedCollaboratorOut} and HTTP response
     */
    getCollaboratorWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getCollaborator");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedCollaboratorOut;
      return this.apiClient.callApi(
        '/api/collaborator/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Collaborator
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedCollaboratorOut}
     */
    getCollaborator(id) {
      return this.getCollaboratorWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get ExternalService
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedExternalServiceOut} and HTTP response
     */
    getExternalserviceWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getExternalservice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedExternalServiceOut;
      return this.apiClient.callApi(
        '/api/external/service/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get ExternalService
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedExternalServiceOut}
     */
    getExternalservice(id) {
      return this.getExternalserviceWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/KonohaOut} and HTTP response
     */
    getKonohaJwtWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getKonohaJwt");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = KonohaOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/konoha/token', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/KonohaOut}
     */
    getKonohaJwt(id) {
      return this.getKonohaJwtWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ActiveCampaignOut>} and HTTP response
     */
    getMyCampaignsInOrganizationWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getMyCampaignsInOrganization");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ActiveCampaignOut];
      return this.apiClient.callApi(
        '/api/organization/{id}/campaigns/active', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ActiveCampaignOut>}
     */
    getMyCampaignsInOrganization(id) {
      return this.getMyCampaignsInOrganizationWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedCollaboratorOut} and HTTP response
     */
    getMyCollaboratorWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getMyCollaborator");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedCollaboratorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/collaborator', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedCollaboratorOut}
     */
    getMyCollaborator(id) {
      return this.getMyCollaboratorWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Organization
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedOrganizationOut} and HTTP response
     */
    getOrganizationWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganization");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedOrganizationOut;
      return this.apiClient.callApi(
        '/api/organization/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Organization
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedOrganizationOut}
     */
    getOrganization(id) {
      return this.getOrganizationWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [id2] 
     * @param {String} [name] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatalakeOut} and HTTP response
     */
    getOrganizationDatalakeWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganizationDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': opts['id2'],
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DatalakeOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datalake', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.id2 
     * @param {String} opts.name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatalakeOut}
     */
    getOrganizationDatalake(id, opts) {
      return this.getOrganizationDatalakeWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TorakkaOut} and HTTP response
     */
    getTorrakaJwtWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getTorrakaJwt");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TorakkaOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/torakka/token', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TorakkaOut}
     */
    getTorrakaJwt(id) {
      return this.getTorrakaJwtWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/PendingInvitationIn} pendingInvitationIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PendingUserOut} and HTTP response
     */
    inviteUserWithHttpInfo(id, pendingInvitationIn) {
      let postBody = pendingInvitationIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling inviteUser");
      }
      // verify the required parameter 'pendingInvitationIn' is set
      if (pendingInvitationIn === undefined || pendingInvitationIn === null) {
        throw new Error("Missing the required parameter 'pendingInvitationIn' when calling inviteUser");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PendingUserOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/invite', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/PendingInvitationIn} pendingInvitationIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PendingUserOut}
     */
    inviteUser(id, pendingInvitationIn) {
      return this.inviteUserWithHttpInfo(id, pendingInvitationIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Collaborators
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [username] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedCollaboratorOut} and HTTP response
     */
    listOrganizationCollaboratorsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationCollaborators");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'username': opts['username'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedCollaboratorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/collaborators', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Collaborators
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.username 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedCollaboratorOut}
     */
    listOrganizationCollaborators(id, opts) {
      return this.listOrganizationCollaboratorsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List ComputeConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedComputeConnectorOut} and HTTP response
     */
    listOrganizationComputeconnectorsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationComputeconnectors");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedComputeConnectorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/computeconnectors', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List ComputeConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedComputeConnectorOut}
     */
    listOrganizationComputeconnectors(id, opts) {
      return this.listOrganizationComputeconnectorsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List CvatConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedCvatConnectorOut} and HTTP response
     */
    listOrganizationCvatconnectorsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationCvatconnectors");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedCvatConnectorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/cvatconnectors', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List CvatConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedCvatConnectorOut}
     */
    listOrganizationCvatconnectors(id, opts) {
      return this.listOrganizationCvatconnectorsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datalakes
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedDatalakeOut} and HTTP response
     */
    listOrganizationDatalakesWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDatalakes");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedDatalakeOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datalakes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datalakes
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedDatalakeOut}
     */
    listOrganizationDatalakes(id, opts) {
      return this.listOrganizationDatalakesWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datalakes [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedExtendedDatalakeOut} and HTTP response
     */
    listOrganizationDatalakesExtendedWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDatalakesExtended");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedExtendedDatalakeOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datalakes/extended', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datalakes [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedExtendedDatalakeOut}
     */
    listOrganizationDatalakesExtended(id, opts) {
      return this.listOrganizationDatalakesExtendedWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datasets
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedDatasetOut} and HTTP response
     */
    listOrganizationDatasetsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDatasets");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedDatasetOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datasets
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedDatasetOut}
     */
    listOrganizationDatasets(id, opts) {
      return this.listOrganizationDatasetsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List DataSources
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedDataSourceOut} and HTTP response
     */
    listOrganizationDatasourcesWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDatasources");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedDataSourceOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datasources', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List DataSources
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedDataSourceOut}
     */
    listOrganizationDatasources(id, opts) {
      return this.listOrganizationDatasourcesWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Deployments
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Boolean} [active] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedDeploymentOut} and HTTP response
     */
    listOrganizationDeploymentsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDeployments");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'active': opts['active'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedDeploymentOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/deployments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Deployments
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Boolean} opts.active 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedDeploymentOut}
     */
    listOrganizationDeployments(id, opts) {
      return this.listOrganizationDeploymentsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Deployments [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Boolean} [active] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedExtendedDeploymentOut} and HTTP response
     */
    listOrganizationDeploymentsExtendedWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDeploymentsExtended");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'active': opts['active'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedExtendedDeploymentOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/deployments/extended', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Deployments [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Boolean} opts.active 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedExtendedDeploymentOut}
     */
    listOrganizationDeploymentsExtended(id, opts) {
      return this.listOrganizationDeploymentsExtendedWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Deployments [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Boolean} [active] 
     * @param {Array.<String>} [orderBy] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
     */
    listOrganizationDeploymentsIdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDeploymentsIds");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'active': opts['active'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/api/organization/{id}/deployments/ids', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Deployments [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Boolean} opts.active 
     * @param {Array.<String>} opts.orderBy 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
     */
    listOrganizationDeploymentsIds(id, opts) {
      return this.listOrganizationDeploymentsIdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List DockerConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedDockerConnectorOut} and HTTP response
     */
    listOrganizationDockerconnectorsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationDockerconnectors");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedDockerConnectorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/dockerconnectors', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List DockerConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedDockerConnectorOut}
     */
    listOrganizationDockerconnectors(id, opts) {
      return this.listOrganizationDockerconnectorsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List JobV2s
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [ids] 
     * @param {Array.<module:model/JobType>} [types] 
     * @param {String} [experimentId] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedExtendedJobOut} and HTTP response
     */
    listOrganizationJobv2sWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationJobv2s");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'multi'),
        'types': this.apiClient.buildCollectionParam(opts['types'], 'multi'),
        'experiment_id': opts['experimentId'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedExtendedJobOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/jobv2s', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List JobV2s
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.ids 
     * @param {Array.<module:model/JobType>} opts.types 
     * @param {String} opts.experimentId 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedExtendedJobOut}
     */
    listOrganizationJobv2s(id, opts) {
      return this.listOrganizationJobv2sWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List JobV2s [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [ids] 
     * @param {Array.<module:model/JobType>} [types] 
     * @param {String} [experimentId] 
     * @param {Array.<String>} [orderBy] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
     */
    listOrganizationJobv2sIdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationJobv2sIds");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'multi'),
        'types': this.apiClient.buildCollectionParam(opts['types'], 'multi'),
        'experiment_id': opts['experimentId'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/api/organization/{id}/jobv2s/ids', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List JobV2s [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.ids 
     * @param {Array.<module:model/JobType>} opts.types 
     * @param {String} opts.experimentId 
     * @param {Array.<String>} opts.orderBy 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
     */
    listOrganizationJobv2sIds(id, opts) {
      return this.listOrganizationJobv2sIdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Models
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {module:model/InferenceType} [type] 
     * @param {module:model/FrameworkEnum} [framework] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedModelOut} and HTTP response
     */
    listOrganizationModelsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationModels");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'type': opts['type'],
        'framework': opts['framework'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedModelOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/models', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Models
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:model/InferenceType} opts.type 
     * @param {module:model/FrameworkEnum} opts.framework 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedModelOut}
     */
    listOrganizationModels(id, opts) {
      return this.listOrganizationModelsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Models [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {module:model/InferenceType} [type] 
     * @param {module:model/FrameworkEnum} [framework] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedExtendedModelOut} and HTTP response
     */
    listOrganizationModelsExtendedWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationModelsExtended");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'type': opts['type'],
        'framework': opts['framework'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedExtendedModelOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/models/extended', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Models [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:model/InferenceType} opts.type 
     * @param {module:model/FrameworkEnum} opts.framework 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedExtendedModelOut}
     */
    listOrganizationModelsExtended(id, opts) {
      return this.listOrganizationModelsExtendedWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List ObjectStorageConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedObjectStorageConnectorOut} and HTTP response
     */
    listOrganizationObjectstorageconnectorsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationObjectstorageconnectors");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedObjectStorageConnectorOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/objectstorageconnectors', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List ObjectStorageConnectors
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedObjectStorageConnectorOut}
     */
    listOrganizationObjectstorageconnectors(id, opts) {
      return this.listOrganizationObjectstorageconnectorsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List PendingUsers
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedPendingUserOut} and HTTP response
     */
    listOrganizationPendingusersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationPendingusers");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedPendingUserOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/pendingusers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List PendingUsers
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedPendingUserOut}
     */
    listOrganizationPendingusers(id, opts) {
      return this.listOrganizationPendingusersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Processings
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedProcessingOut} and HTTP response
     */
    listOrganizationProcessingsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationProcessings");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedProcessingOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/processings', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Processings
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedProcessingOut}
     */
    listOrganizationProcessings(id, opts) {
      return this.listOrganizationProcessingsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Projects
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedProjectOut} and HTTP response
     */
    listOrganizationProjectsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationProjects");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedProjectOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/projects', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Projects
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedProjectOut}
     */
    listOrganizationProjects(id, opts) {
      return this.listOrganizationProjectsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Projects [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedExtendedProjectOut} and HTTP response
     */
    listOrganizationProjectsExtendedWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationProjectsExtended");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedExtendedProjectOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/projects/extended', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Projects [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedExtendedProjectOut}
     */
    listOrganizationProjectsExtended(id, opts) {
      return this.listOrganizationProjectsExtendedWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Tags
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/TagTargetEnum} [targetType] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedTagOut} and HTTP response
     */
    listOrganizationTagsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationTags");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'target_type': opts['targetType'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedTagOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/tags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Tags
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/TagTargetEnum} opts.targetType 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedTagOut}
     */
    listOrganizationTags(id, opts) {
      return this.listOrganizationTagsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Webhooks
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedWebhookOut} and HTTP response
     */
    listOrganizationWebhooksWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listOrganizationWebhooks");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedWebhookOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/webhooks', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Webhooks
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedWebhookOut}
     */
    listOrganizationWebhooks(id, opts) {
      return this.listOrganizationWebhooksWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MyOrganizationsOut} and HTTP response
     */
    listOrganizationsWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MyOrganizationsOut;
      return this.apiClient.callApi(
        '/api/organizations/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MyOrganizationsOut}
     */
    listOrganizations() {
      return this.listOrganizationsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Deployment
     * @param {String} id 
     * @param {module:model/DeploymentIn} deploymentIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeploymentOut} and HTTP response
     */
    organizationApiOrganizationCreateDeploymentWithHttpInfo(id, deploymentIn) {
      let postBody = deploymentIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling organizationApiOrganizationCreateDeployment");
      }
      // verify the required parameter 'deploymentIn' is set
      if (deploymentIn === undefined || deploymentIn === null) {
        throw new Error("Missing the required parameter 'deploymentIn' when calling organizationApiOrganizationCreateDeployment");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeploymentOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/deployments', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create Deployment
     * @param {String} id 
     * @param {module:model/DeploymentIn} deploymentIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeploymentOut}
     */
    organizationApiOrganizationCreateDeployment(id, deploymentIn) {
      return this.organizationApiOrganizationCreateDeploymentWithHttpInfo(id, deploymentIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/String} targetType 
     * @param {module:model/TagIn} tagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagOut} and HTTP response
     */
    organizationChildCreateTagsWithHttpInfo(id, targetType, tagIn) {
      let postBody = tagIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling organizationChildCreateTags");
      }
      // verify the required parameter 'targetType' is set
      if (targetType === undefined || targetType === null) {
        throw new Error("Missing the required parameter 'targetType' when calling organizationChildCreateTags");
      }
      // verify the required parameter 'tagIn' is set
      if (tagIn === undefined || tagIn === null) {
        throw new Error("Missing the required parameter 'tagIn' when calling organizationChildCreateTags");
      }

      let pathParams = {
        'id': id,
        'target_type': targetType
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = TagOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/{target_type}/tags', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/String} targetType 
     * @param {module:model/TagIn} tagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagOut}
     */
    organizationChildCreateTags(id, targetType, tagIn) {
      return this.organizationChildCreateTagsWithHttpInfo(id, targetType, tagIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/String} targetType 
     * @param {String} name 
     * @param {Object} opts Optional parameters
     * @param {String} [color] 
     * @param {String} [parentId] 
     * @param {String} [description] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagOut} and HTTP response
     */
    organizationChildFindTagWithHttpInfo(id, targetType, name, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling organizationChildFindTag");
      }
      // verify the required parameter 'targetType' is set
      if (targetType === undefined || targetType === null) {
        throw new Error("Missing the required parameter 'targetType' when calling organizationChildFindTag");
      }
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling organizationChildFindTag");
      }

      let pathParams = {
        'id': id,
        'target_type': targetType
      };
      let queryParams = {
        'color': opts['color'],
        'parent_id': opts['parentId'],
        'name': name,
        'description': opts['description']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TagOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/{target_type}/tags/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/String} targetType 
     * @param {String} name 
     * @param {Object} opts Optional parameters
     * @param {String} opts.color 
     * @param {String} opts.parentId 
     * @param {String} opts.description 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagOut}
     */
    organizationChildFindTag(id, targetType, name, opts) {
      return this.organizationChildFindTagWithHttpInfo(id, targetType, name, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/String} targetType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/TagOut>} and HTTP response
     */
    organizationChildListTagsWithHttpInfo(id, targetType) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling organizationChildListTags");
      }
      // verify the required parameter 'targetType' is set
      if (targetType === undefined || targetType === null) {
        throw new Error("Missing the required parameter 'targetType' when calling organizationChildListTags");
      }

      let pathParams = {
        'id': id,
        'target_type': targetType
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [TagOut];
      return this.apiClient.callApi(
        '/api/organization/{id}/{target_type}/tags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/String} targetType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/TagOut>}
     */
    organizationChildListTags(id, targetType) {
      return this.organizationChildListTagsWithHttpInfo(id, targetType)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch ExternalService
     * @param {String} id 
     * @param {module:model/OptionalExternalServiceIn} optionalExternalServiceIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedExternalServiceOut} and HTTP response
     */
    patchExternalserviceWithHttpInfo(id, optionalExternalServiceIn) {
      let postBody = optionalExternalServiceIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling patchExternalservice");
      }
      // verify the required parameter 'optionalExternalServiceIn' is set
      if (optionalExternalServiceIn === undefined || optionalExternalServiceIn === null) {
        throw new Error("Missing the required parameter 'optionalExternalServiceIn' when calling patchExternalservice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedExternalServiceOut;
      return this.apiClient.callApi(
        '/api/external/service/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Patch ExternalService
     * @param {String} id 
     * @param {module:model/OptionalExternalServiceIn} optionalExternalServiceIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedExternalServiceOut}
     */
    patchExternalservice(id, optionalExternalServiceIn) {
      return this.patchExternalserviceWithHttpInfo(id, optionalExternalServiceIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch Organization
     * @param {String} id 
     * @param {module:model/OptionalOrganizationUpdateIn} optionalOrganizationUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedOrganizationOut} and HTTP response
     */
    patchOrganizationWithHttpInfo(id, optionalOrganizationUpdateIn) {
      let postBody = optionalOrganizationUpdateIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling patchOrganization");
      }
      // verify the required parameter 'optionalOrganizationUpdateIn' is set
      if (optionalOrganizationUpdateIn === undefined || optionalOrganizationUpdateIn === null) {
        throw new Error("Missing the required parameter 'optionalOrganizationUpdateIn' when calling patchOrganization");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedOrganizationOut;
      return this.apiClient.callApi(
        '/api/organization/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Patch Organization
     * @param {String} id 
     * @param {module:model/OptionalOrganizationUpdateIn} optionalOrganizationUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedOrganizationOut}
     */
    patchOrganization(id, optionalOrganizationUpdateIn) {
      return this.patchOrganizationWithHttpInfo(id, optionalOrganizationUpdateIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/MyobokuRegisterIn} myobokuRegisterIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MyobokuRegisterOut} and HTTP response
     */
    registerMyobokuWithHttpInfo(id, myobokuRegisterIn) {
      let postBody = myobokuRegisterIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling registerMyoboku");
      }
      // verify the required parameter 'myobokuRegisterIn' is set
      if (myobokuRegisterIn === undefined || myobokuRegisterIn === null) {
        throw new Error("Missing the required parameter 'myobokuRegisterIn' when calling registerMyoboku");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = MyobokuRegisterOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/myoboku/register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/MyobokuRegisterIn} myobokuRegisterIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MyobokuRegisterOut}
     */
    registerMyoboku(id, myobokuRegisterIn) {
      return this.registerMyobokuWithHttpInfo(id, myobokuRegisterIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/MyobokuSetupIn} myobokuSetupIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    setComputeConnectorWithHttpInfo(id, myobokuSetupIn) {
      let postBody = myobokuSetupIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setComputeConnector");
      }
      // verify the required parameter 'myobokuSetupIn' is set
      if (myobokuSetupIn === undefined || myobokuSetupIn === null) {
        throw new Error("Missing the required parameter 'myobokuSetupIn' when calling setComputeConnector");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/organization/{id}/myoboku', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/MyobokuSetupIn} myobokuSetupIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    setComputeConnector(id, myobokuSetupIn) {
      return this.setComputeConnectorWithHttpInfo(id, myobokuSetupIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/DefaultDatalakeUpdateIn} defaultDatalakeUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatalakeOut} and HTTP response
     */
    setDefaultDatalakeWithHttpInfo(id, defaultDatalakeUpdateIn) {
      let postBody = defaultDatalakeUpdateIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setDefaultDatalake");
      }
      // verify the required parameter 'defaultDatalakeUpdateIn' is set
      if (defaultDatalakeUpdateIn === undefined || defaultDatalakeUpdateIn === null) {
        throw new Error("Missing the required parameter 'defaultDatalakeUpdateIn' when calling setDefaultDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DatalakeOut;
      return this.apiClient.callApi(
        '/api/organization/{id}/datalake/default', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/DefaultDatalakeUpdateIn} defaultDatalakeUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatalakeOut}
     */
    setDefaultDatalake(id, defaultDatalakeUpdateIn) {
      return this.setDefaultDatalakeWithHttpInfo(id, defaultDatalakeUpdateIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/CollaboratorPermissionsUpdateIn} collaboratorPermissionsUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedCollaboratorOut} and HTTP response
     */
    updateCollaboratorWithHttpInfo(id, collaboratorPermissionsUpdateIn) {
      let postBody = collaboratorPermissionsUpdateIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateCollaborator");
      }
      // verify the required parameter 'collaboratorPermissionsUpdateIn' is set
      if (collaboratorPermissionsUpdateIn === undefined || collaboratorPermissionsUpdateIn === null) {
        throw new Error("Missing the required parameter 'collaboratorPermissionsUpdateIn' when calling updateCollaborator");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedCollaboratorOut;
      return this.apiClient.callApi(
        '/api/collaborator/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/CollaboratorPermissionsUpdateIn} collaboratorPermissionsUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedCollaboratorOut}
     */
    updateCollaborator(id, collaboratorPermissionsUpdateIn) {
      return this.updateCollaboratorWithHttpInfo(id, collaboratorPermissionsUpdateIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update ExternalService
     * @param {String} id 
     * @param {module:model/ExternalServiceIn} externalServiceIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedExternalServiceOut} and HTTP response
     */
    updateExternalserviceWithHttpInfo(id, externalServiceIn) {
      let postBody = externalServiceIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateExternalservice");
      }
      // verify the required parameter 'externalServiceIn' is set
      if (externalServiceIn === undefined || externalServiceIn === null) {
        throw new Error("Missing the required parameter 'externalServiceIn' when calling updateExternalservice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedExternalServiceOut;
      return this.apiClient.callApi(
        '/api/external/service/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update ExternalService
     * @param {String} id 
     * @param {module:model/ExternalServiceIn} externalServiceIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedExternalServiceOut}
     */
    updateExternalservice(id, externalServiceIn) {
      return this.updateExternalserviceWithHttpInfo(id, externalServiceIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update Organization
     * @param {String} id 
     * @param {module:model/OrganizationUpdateIn} organizationUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedOrganizationOut} and HTTP response
     */
    updateOrganizationWithHttpInfo(id, organizationUpdateIn) {
      let postBody = organizationUpdateIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateOrganization");
      }
      // verify the required parameter 'organizationUpdateIn' is set
      if (organizationUpdateIn === undefined || organizationUpdateIn === null) {
        throw new Error("Missing the required parameter 'organizationUpdateIn' when calling updateOrganization");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedOrganizationOut;
      return this.apiClient.callApi(
        '/api/organization/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update Organization
     * @param {String} id 
     * @param {module:model/OrganizationUpdateIn} organizationUpdateIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedOrganizationOut}
     */
    updateOrganization(id, organizationUpdateIn) {
      return this.updateOrganizationWithHttpInfo(id, organizationUpdateIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
