/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ObjectDataTypeEnum.
* @enum {}
* @readonly
*/
export default class ObjectDataTypeEnum {
    
        /**
         * value: "ANNOTATIONS"
         * @const
         */
        "ANNOTATIONS" = "ANNOTATIONS";

    
        /**
         * value: "ARTIFACT"
         * @const
         */
        "ARTIFACT" = "ARTIFACT";

    
        /**
         * value: "DATA"
         * @const
         */
        "DATA" = "DATA";

    
        /**
         * value: "DATA_PROJECTION"
         * @const
         */
        "DATA_PROJECTION" = "DATA_PROJECTION";

    
        /**
         * value: "LOGGING"
         * @const
         */
        "LOGGING" = "LOGGING";

    
        /**
         * value: "LOG_IMAGE"
         * @const
         */
        "LOG_IMAGE" = "LOG_IMAGE";

    
        /**
         * value: "MODEL_FILE"
         * @const
         */
        "MODEL_FILE" = "MODEL_FILE";

    
        /**
         * value: "MODEL_THUMB"
         * @const
         */
        "MODEL_THUMB" = "MODEL_THUMB";

    
        /**
         * value: "SCAN_FILE"
         * @const
         */
        "SCAN_FILE" = "SCAN_FILE";

    
        /**
         * value: "PREDICTION_DATA"
         * @const
         */
        "PREDICTION_DATA" = "PREDICTION_DATA";

    
        /**
         * value: "CAMPAIGN_FILE"
         * @const
         */
        "CAMPAIGN_FILE" = "CAMPAIGN_FILE";

    

    /**
    * Returns a <code>ObjectDataTypeEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ObjectDataTypeEnum} The enum <code>ObjectDataTypeEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

