/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DatasetVersionConstraints from './DatasetVersionConstraints';
import FrameworkEnum from './FrameworkEnum';
import InferenceType from './InferenceType';
import ModelOut from './ModelOut';
import PicturedUserOut from './PicturedUserOut';
import TagOut from './TagOut';

/**
 * The ExtendedModelVersionOut model module.
 * @module model/ExtendedModelVersionOut
 * @version 1.0.0
 */
class ExtendedModelVersionOut {
    /**
     * Constructs a new <code>ExtendedModelVersionOut</code>.
     * @alias module:model/ExtendedModelVersionOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param dockerRegistry {String} 
     * @param dockerImage {String} 
     * @param dockerTag {String} 
     * @param dockerFlags {Array.<String>} 
     * @param dockerEnvVariables {Object} 
     * @param name {String} 
     * @param description {String} 
     * @param thumbnailObjectName {String} 
     * @param id {String} 
     * @param version {Number} 
     * @param type {module:model/InferenceType} 
     * @param framework {module:model/FrameworkEnum} 
     * @param presignedUrl {String} 
     * @param labels {Object} 
     * @param parameters {Object} 
     * @param parametersSchema {Object} 
     * @param datasetVersionConstraints {module:model/DatasetVersionConstraints} 
     * @param notebookLink {String} 
     * @param origin {module:model/ModelOut} 
     * @param dockerImageName {String} 
     * @param thumbObjectName {String} 
     * @param baseParameters {Object} 
     * @param tags {Array.<module:model/TagOut>} 
     */
    constructor(createdAt, updatedAt, createdBy, dockerRegistry, dockerImage, dockerTag, dockerFlags, dockerEnvVariables, name, description, thumbnailObjectName, id, version, type, framework, presignedUrl, labels, parameters, parametersSchema, datasetVersionConstraints, notebookLink, origin, dockerImageName, thumbObjectName, baseParameters, tags) { 
        
        ExtendedModelVersionOut.initialize(this, createdAt, updatedAt, createdBy, dockerRegistry, dockerImage, dockerTag, dockerFlags, dockerEnvVariables, name, description, thumbnailObjectName, id, version, type, framework, presignedUrl, labels, parameters, parametersSchema, datasetVersionConstraints, notebookLink, origin, dockerImageName, thumbObjectName, baseParameters, tags);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, dockerRegistry, dockerImage, dockerTag, dockerFlags, dockerEnvVariables, name, description, thumbnailObjectName, id, version, type, framework, presignedUrl, labels, parameters, parametersSchema, datasetVersionConstraints, notebookLink, origin, dockerImageName, thumbObjectName, baseParameters, tags) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['docker_registry'] = dockerRegistry;
        obj['docker_image'] = dockerImage;
        obj['docker_tag'] = dockerTag;
        obj['docker_flags'] = dockerFlags;
        obj['docker_env_variables'] = dockerEnvVariables;
        obj['name'] = name;
        obj['description'] = description;
        obj['thumbnail_object_name'] = thumbnailObjectName;
        obj['id'] = id;
        obj['version'] = version;
        obj['type'] = type;
        obj['framework'] = framework;
        obj['presigned_url'] = presignedUrl;
        obj['labels'] = labels;
        obj['parameters'] = parameters;
        obj['parameters_schema'] = parametersSchema;
        obj['dataset_version_constraints'] = datasetVersionConstraints;
        obj['notebook_link'] = notebookLink;
        obj['origin'] = origin;
        obj['docker_image_name'] = dockerImageName;
        obj['thumb_object_name'] = thumbObjectName;
        obj['base_parameters'] = baseParameters;
        obj['tags'] = tags;
    }

    /**
     * Constructs a <code>ExtendedModelVersionOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedModelVersionOut} obj Optional instance to populate.
     * @return {module:model/ExtendedModelVersionOut} The populated <code>ExtendedModelVersionOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedModelVersionOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('docker_registry')) {
                obj['docker_registry'] = ApiClient.convertToType(data['docker_registry'], 'String');
            }
            if (data.hasOwnProperty('docker_image')) {
                obj['docker_image'] = ApiClient.convertToType(data['docker_image'], 'String');
            }
            if (data.hasOwnProperty('docker_tag')) {
                obj['docker_tag'] = ApiClient.convertToType(data['docker_tag'], 'String');
            }
            if (data.hasOwnProperty('docker_flags')) {
                obj['docker_flags'] = ApiClient.convertToType(data['docker_flags'], ['String']);
            }
            if (data.hasOwnProperty('docker_env_variables')) {
                obj['docker_env_variables'] = ApiClient.convertToType(data['docker_env_variables'], Object);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail_object_name')) {
                obj['thumbnail_object_name'] = ApiClient.convertToType(data['thumbnail_object_name'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = InferenceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('framework')) {
                obj['framework'] = FrameworkEnum.constructFromObject(data['framework']);
            }
            if (data.hasOwnProperty('presigned_url')) {
                obj['presigned_url'] = ApiClient.convertToType(data['presigned_url'], 'String');
            }
            if (data.hasOwnProperty('labels')) {
                obj['labels'] = ApiClient.convertToType(data['labels'], Object);
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], Object);
            }
            if (data.hasOwnProperty('parameters_schema')) {
                obj['parameters_schema'] = ApiClient.convertToType(data['parameters_schema'], Object);
            }
            if (data.hasOwnProperty('dataset_version_constraints')) {
                obj['dataset_version_constraints'] = DatasetVersionConstraints.constructFromObject(data['dataset_version_constraints']);
            }
            if (data.hasOwnProperty('notebook_link')) {
                obj['notebook_link'] = ApiClient.convertToType(data['notebook_link'], 'String');
            }
            if (data.hasOwnProperty('origin')) {
                obj['origin'] = ModelOut.constructFromObject(data['origin']);
            }
            if (data.hasOwnProperty('docker_image_name')) {
                obj['docker_image_name'] = ApiClient.convertToType(data['docker_image_name'], 'String');
            }
            if (data.hasOwnProperty('thumb_object_name')) {
                obj['thumb_object_name'] = ApiClient.convertToType(data['thumb_object_name'], 'String');
            }
            if (data.hasOwnProperty('base_parameters')) {
                obj['base_parameters'] = ApiClient.convertToType(data['base_parameters'], Object);
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [TagOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedModelVersionOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedModelVersionOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedModelVersionOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['docker_registry'] && !(typeof data['docker_registry'] === 'string' || data['docker_registry'] instanceof String)) {
            throw new Error("Expected the field `docker_registry` to be a primitive type in the JSON string but got " + data['docker_registry']);
        }
        // ensure the json data is a string
        if (data['docker_image'] && !(typeof data['docker_image'] === 'string' || data['docker_image'] instanceof String)) {
            throw new Error("Expected the field `docker_image` to be a primitive type in the JSON string but got " + data['docker_image']);
        }
        // ensure the json data is a string
        if (data['docker_tag'] && !(typeof data['docker_tag'] === 'string' || data['docker_tag'] instanceof String)) {
            throw new Error("Expected the field `docker_tag` to be a primitive type in the JSON string but got " + data['docker_tag']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['docker_flags'])) {
            throw new Error("Expected the field `docker_flags` to be an array in the JSON data but got " + data['docker_flags']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['thumbnail_object_name'] && !(typeof data['thumbnail_object_name'] === 'string' || data['thumbnail_object_name'] instanceof String)) {
            throw new Error("Expected the field `thumbnail_object_name` to be a primitive type in the JSON string but got " + data['thumbnail_object_name']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['presigned_url'] && !(typeof data['presigned_url'] === 'string' || data['presigned_url'] instanceof String)) {
            throw new Error("Expected the field `presigned_url` to be a primitive type in the JSON string but got " + data['presigned_url']);
        }
        // validate the optional field `dataset_version_constraints`
        if (data['dataset_version_constraints']) { // data not null
          DatasetVersionConstraints.validateJSON(data['dataset_version_constraints']);
        }
        // ensure the json data is a string
        if (data['notebook_link'] && !(typeof data['notebook_link'] === 'string' || data['notebook_link'] instanceof String)) {
            throw new Error("Expected the field `notebook_link` to be a primitive type in the JSON string but got " + data['notebook_link']);
        }
        // validate the optional field `origin`
        if (data['origin']) { // data not null
          ModelOut.validateJSON(data['origin']);
        }
        // ensure the json data is a string
        if (data['docker_image_name'] && !(typeof data['docker_image_name'] === 'string' || data['docker_image_name'] instanceof String)) {
            throw new Error("Expected the field `docker_image_name` to be a primitive type in the JSON string but got " + data['docker_image_name']);
        }
        // ensure the json data is a string
        if (data['thumb_object_name'] && !(typeof data['thumb_object_name'] === 'string' || data['thumb_object_name'] instanceof String)) {
            throw new Error("Expected the field `thumb_object_name` to be a primitive type in the JSON string but got " + data['thumb_object_name']);
        }
        if (data['tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tags'])) {
                throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
            }
            // validate the optional field `tags` (array)
            for (const item of data['tags']) {
                TagOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExtendedModelVersionOut.RequiredProperties = ["created_at", "updated_at", "created_by", "docker_registry", "docker_image", "docker_tag", "docker_flags", "docker_env_variables", "name", "description", "thumbnail_object_name", "id", "version", "type", "framework", "presigned_url", "labels", "parameters", "parameters_schema", "dataset_version_constraints", "notebook_link", "origin", "docker_image_name", "thumb_object_name", "base_parameters", "tags"];

/**
 * @member {Date} created_at
 */
ExtendedModelVersionOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedModelVersionOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ExtendedModelVersionOut.prototype['created_by'] = undefined;

/**
 * @member {String} docker_registry
 */
ExtendedModelVersionOut.prototype['docker_registry'] = undefined;

/**
 * @member {String} docker_image
 */
ExtendedModelVersionOut.prototype['docker_image'] = undefined;

/**
 * @member {String} docker_tag
 */
ExtendedModelVersionOut.prototype['docker_tag'] = undefined;

/**
 * @member {Array.<String>} docker_flags
 */
ExtendedModelVersionOut.prototype['docker_flags'] = undefined;

/**
 * @member {Object} docker_env_variables
 */
ExtendedModelVersionOut.prototype['docker_env_variables'] = undefined;

/**
 * @member {String} name
 */
ExtendedModelVersionOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ExtendedModelVersionOut.prototype['description'] = undefined;

/**
 * @member {String} thumbnail_object_name
 */
ExtendedModelVersionOut.prototype['thumbnail_object_name'] = undefined;

/**
 * @member {String} id
 */
ExtendedModelVersionOut.prototype['id'] = undefined;

/**
 * @member {Number} version
 */
ExtendedModelVersionOut.prototype['version'] = undefined;

/**
 * @member {module:model/InferenceType} type
 */
ExtendedModelVersionOut.prototype['type'] = undefined;

/**
 * @member {module:model/FrameworkEnum} framework
 */
ExtendedModelVersionOut.prototype['framework'] = undefined;

/**
 * @member {String} presigned_url
 */
ExtendedModelVersionOut.prototype['presigned_url'] = undefined;

/**
 * @member {Object} labels
 */
ExtendedModelVersionOut.prototype['labels'] = undefined;

/**
 * @member {Object} parameters
 */
ExtendedModelVersionOut.prototype['parameters'] = undefined;

/**
 * @member {Object} parameters_schema
 */
ExtendedModelVersionOut.prototype['parameters_schema'] = undefined;

/**
 * @member {module:model/DatasetVersionConstraints} dataset_version_constraints
 */
ExtendedModelVersionOut.prototype['dataset_version_constraints'] = undefined;

/**
 * @member {String} notebook_link
 */
ExtendedModelVersionOut.prototype['notebook_link'] = undefined;

/**
 * @member {module:model/ModelOut} origin
 */
ExtendedModelVersionOut.prototype['origin'] = undefined;

/**
 * @member {String} docker_image_name
 */
ExtendedModelVersionOut.prototype['docker_image_name'] = undefined;

/**
 * @member {String} thumb_object_name
 */
ExtendedModelVersionOut.prototype['thumb_object_name'] = undefined;

/**
 * @member {Object} base_parameters
 */
ExtendedModelVersionOut.prototype['base_parameters'] = undefined;

/**
 * @member {Array.<module:model/TagOut>} tags
 */
ExtendedModelVersionOut.prototype['tags'] = undefined;






export default ExtendedModelVersionOut;

