/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class FrameworkEnum.
* @enum {}
* @readonly
*/
export default class FrameworkEnum {
    
        /**
         * value: "NOT_CONFIGURED"
         * @const
         */
        "NOT_CONFIGURED" = "NOT_CONFIGURED";

    
        /**
         * value: "TENSORFLOW"
         * @const
         */
        "TENSORFLOW" = "TENSORFLOW";

    
        /**
         * value: "PYTORCH"
         * @const
         */
        "PYTORCH" = "PYTORCH";

    
        /**
         * value: "ONNX"
         * @const
         */
        "ONNX" = "ONNX";

    

    /**
    * Returns a <code>FrameworkEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/FrameworkEnum} The enum <code>FrameworkEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

