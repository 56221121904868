/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FrameworkEnum from './FrameworkEnum';
import InferenceType from './InferenceType';
import PicturedUserOut from './PicturedUserOut';
import TagOut from './TagOut';

/**
 * The ExtendedModelOut model module.
 * @module model/ExtendedModelOut
 * @version 1.0.0
 */
class ExtendedModelOut {
    /**
     * Constructs a new <code>ExtendedModelOut</code>.
     * @alias module:model/ExtendedModelOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param name {String} 
     * @param description {String} 
     * @param thumbnailObjectName {String} 
     * @param id {String} 
     * @param _private {Boolean} 
     * @param type {module:model/InferenceType} 
     * @param framework {module:model/FrameworkEnum} 
     * @param tags {Array.<module:model/TagOut>} 
     * @param nbVersions {Number} 
     * @param presignedUrl {String} 
     */
    constructor(createdAt, updatedAt, createdBy, name, description, thumbnailObjectName, id, _private, type, framework, tags, nbVersions, presignedUrl) { 
        
        ExtendedModelOut.initialize(this, createdAt, updatedAt, createdBy, name, description, thumbnailObjectName, id, _private, type, framework, tags, nbVersions, presignedUrl);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, name, description, thumbnailObjectName, id, _private, type, framework, tags, nbVersions, presignedUrl) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['name'] = name;
        obj['description'] = description;
        obj['thumbnail_object_name'] = thumbnailObjectName;
        obj['id'] = id;
        obj['private'] = _private;
        obj['type'] = type;
        obj['framework'] = framework;
        obj['tags'] = tags;
        obj['nb_versions'] = nbVersions;
        obj['presigned_url'] = presignedUrl;
    }

    /**
     * Constructs a <code>ExtendedModelOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedModelOut} obj Optional instance to populate.
     * @return {module:model/ExtendedModelOut} The populated <code>ExtendedModelOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedModelOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail_object_name')) {
                obj['thumbnail_object_name'] = ApiClient.convertToType(data['thumbnail_object_name'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('private')) {
                obj['private'] = ApiClient.convertToType(data['private'], 'Boolean');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = InferenceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('framework')) {
                obj['framework'] = FrameworkEnum.constructFromObject(data['framework']);
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [TagOut]);
            }
            if (data.hasOwnProperty('nb_versions')) {
                obj['nb_versions'] = ApiClient.convertToType(data['nb_versions'], 'Number');
            }
            if (data.hasOwnProperty('presigned_url')) {
                obj['presigned_url'] = ApiClient.convertToType(data['presigned_url'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedModelOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedModelOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedModelOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['thumbnail_object_name'] && !(typeof data['thumbnail_object_name'] === 'string' || data['thumbnail_object_name'] instanceof String)) {
            throw new Error("Expected the field `thumbnail_object_name` to be a primitive type in the JSON string but got " + data['thumbnail_object_name']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        if (data['tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tags'])) {
                throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
            }
            // validate the optional field `tags` (array)
            for (const item of data['tags']) {
                TagOut.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['presigned_url'] && !(typeof data['presigned_url'] === 'string' || data['presigned_url'] instanceof String)) {
            throw new Error("Expected the field `presigned_url` to be a primitive type in the JSON string but got " + data['presigned_url']);
        }

        return true;
    }


}

ExtendedModelOut.RequiredProperties = ["created_at", "updated_at", "created_by", "name", "description", "thumbnail_object_name", "id", "private", "type", "framework", "tags", "nb_versions", "presigned_url"];

/**
 * @member {Date} created_at
 */
ExtendedModelOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedModelOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ExtendedModelOut.prototype['created_by'] = undefined;

/**
 * @member {String} name
 */
ExtendedModelOut.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ExtendedModelOut.prototype['description'] = undefined;

/**
 * @member {String} thumbnail_object_name
 */
ExtendedModelOut.prototype['thumbnail_object_name'] = undefined;

/**
 * @member {String} id
 */
ExtendedModelOut.prototype['id'] = undefined;

/**
 * @member {Boolean} private
 */
ExtendedModelOut.prototype['private'] = undefined;

/**
 * @member {module:model/InferenceType} type
 */
ExtendedModelOut.prototype['type'] = undefined;

/**
 * @member {module:model/FrameworkEnum} framework
 */
ExtendedModelOut.prototype['framework'] = undefined;

/**
 * @member {Array.<module:model/TagOut>} tags
 */
ExtendedModelOut.prototype['tags'] = undefined;

/**
 * @member {Number} nb_versions
 */
ExtendedModelOut.prototype['nb_versions'] = undefined;

/**
 * @member {String} presigned_url
 */
ExtendedModelOut.prototype['presigned_url'] = undefined;






export default ExtendedModelOut;

