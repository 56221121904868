/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import InferenceType from './InferenceType';

/**
 * The DeploymentOut model module.
 * @module model/DeploymentOut
 * @version 1.0.0
 */
class DeploymentOut {
    /**
     * Constructs a new <code>DeploymentOut</code>.
     * @alias module:model/DeploymentOut
     * @param id {String} 
     * @param name {String} 
     * @param type {module:model/InferenceType} 
     * @param active {Boolean} 
     * @param targetDatalakeId {String} 
     * @param minThreshold {Number} 
     * @param oracleServiceId {String} 
     * @param oracleHost {String} 
     * @param servingServiceId {String} 
     * @param servingHost {String} 
     * @param modelVersionId {String} 
     * @param shadowModelVersionId {String} 
     * @param createdAt {Date} 
     * @param labels {Object} 
     * @param shadowed {Boolean} 
     */
    constructor(id, name, type, active, targetDatalakeId, minThreshold, oracleServiceId, oracleHost, servingServiceId, servingHost, modelVersionId, shadowModelVersionId, createdAt, labels, shadowed) { 
        
        DeploymentOut.initialize(this, id, name, type, active, targetDatalakeId, minThreshold, oracleServiceId, oracleHost, servingServiceId, servingHost, modelVersionId, shadowModelVersionId, createdAt, labels, shadowed);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, type, active, targetDatalakeId, minThreshold, oracleServiceId, oracleHost, servingServiceId, servingHost, modelVersionId, shadowModelVersionId, createdAt, labels, shadowed) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['type'] = type;
        obj['active'] = active;
        obj['target_datalake_id'] = targetDatalakeId;
        obj['min_threshold'] = minThreshold;
        obj['oracle_service_id'] = oracleServiceId;
        obj['oracle_host'] = oracleHost;
        obj['serving_service_id'] = servingServiceId;
        obj['serving_host'] = servingHost;
        obj['model_version_id'] = modelVersionId;
        obj['shadow_model_version_id'] = shadowModelVersionId;
        obj['created_at'] = createdAt;
        obj['labels'] = labels;
        obj['shadowed'] = shadowed;
    }

    /**
     * Constructs a <code>DeploymentOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeploymentOut} obj Optional instance to populate.
     * @return {module:model/DeploymentOut} The populated <code>DeploymentOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeploymentOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = InferenceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('target_datalake_id')) {
                obj['target_datalake_id'] = ApiClient.convertToType(data['target_datalake_id'], 'String');
            }
            if (data.hasOwnProperty('min_threshold')) {
                obj['min_threshold'] = ApiClient.convertToType(data['min_threshold'], 'Number');
            }
            if (data.hasOwnProperty('oracle_service_id')) {
                obj['oracle_service_id'] = ApiClient.convertToType(data['oracle_service_id'], 'String');
            }
            if (data.hasOwnProperty('oracle_host')) {
                obj['oracle_host'] = ApiClient.convertToType(data['oracle_host'], 'String');
            }
            if (data.hasOwnProperty('serving_service_id')) {
                obj['serving_service_id'] = ApiClient.convertToType(data['serving_service_id'], 'String');
            }
            if (data.hasOwnProperty('serving_host')) {
                obj['serving_host'] = ApiClient.convertToType(data['serving_host'], 'String');
            }
            if (data.hasOwnProperty('model_version_id')) {
                obj['model_version_id'] = ApiClient.convertToType(data['model_version_id'], 'String');
            }
            if (data.hasOwnProperty('shadow_model_version_id')) {
                obj['shadow_model_version_id'] = ApiClient.convertToType(data['shadow_model_version_id'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('labels')) {
                obj['labels'] = ApiClient.convertToType(data['labels'], Object);
            }
            if (data.hasOwnProperty('shadowed')) {
                obj['shadowed'] = ApiClient.convertToType(data['shadowed'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DeploymentOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeploymentOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DeploymentOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['target_datalake_id'] && !(typeof data['target_datalake_id'] === 'string' || data['target_datalake_id'] instanceof String)) {
            throw new Error("Expected the field `target_datalake_id` to be a primitive type in the JSON string but got " + data['target_datalake_id']);
        }
        // ensure the json data is a string
        if (data['oracle_service_id'] && !(typeof data['oracle_service_id'] === 'string' || data['oracle_service_id'] instanceof String)) {
            throw new Error("Expected the field `oracle_service_id` to be a primitive type in the JSON string but got " + data['oracle_service_id']);
        }
        // ensure the json data is a string
        if (data['oracle_host'] && !(typeof data['oracle_host'] === 'string' || data['oracle_host'] instanceof String)) {
            throw new Error("Expected the field `oracle_host` to be a primitive type in the JSON string but got " + data['oracle_host']);
        }
        // ensure the json data is a string
        if (data['serving_service_id'] && !(typeof data['serving_service_id'] === 'string' || data['serving_service_id'] instanceof String)) {
            throw new Error("Expected the field `serving_service_id` to be a primitive type in the JSON string but got " + data['serving_service_id']);
        }
        // ensure the json data is a string
        if (data['serving_host'] && !(typeof data['serving_host'] === 'string' || data['serving_host'] instanceof String)) {
            throw new Error("Expected the field `serving_host` to be a primitive type in the JSON string but got " + data['serving_host']);
        }
        // ensure the json data is a string
        if (data['model_version_id'] && !(typeof data['model_version_id'] === 'string' || data['model_version_id'] instanceof String)) {
            throw new Error("Expected the field `model_version_id` to be a primitive type in the JSON string but got " + data['model_version_id']);
        }
        // ensure the json data is a string
        if (data['shadow_model_version_id'] && !(typeof data['shadow_model_version_id'] === 'string' || data['shadow_model_version_id'] instanceof String)) {
            throw new Error("Expected the field `shadow_model_version_id` to be a primitive type in the JSON string but got " + data['shadow_model_version_id']);
        }

        return true;
    }


}

DeploymentOut.RequiredProperties = ["id", "name", "type", "active", "target_datalake_id", "min_threshold", "oracle_service_id", "oracle_host", "serving_service_id", "serving_host", "model_version_id", "shadow_model_version_id", "created_at", "labels", "shadowed"];

/**
 * @member {String} id
 */
DeploymentOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
DeploymentOut.prototype['name'] = undefined;

/**
 * @member {module:model/InferenceType} type
 */
DeploymentOut.prototype['type'] = undefined;

/**
 * @member {Boolean} active
 */
DeploymentOut.prototype['active'] = undefined;

/**
 * @member {String} target_datalake_id
 */
DeploymentOut.prototype['target_datalake_id'] = undefined;

/**
 * @member {Number} min_threshold
 */
DeploymentOut.prototype['min_threshold'] = undefined;

/**
 * @member {String} oracle_service_id
 */
DeploymentOut.prototype['oracle_service_id'] = undefined;

/**
 * @member {String} oracle_host
 */
DeploymentOut.prototype['oracle_host'] = undefined;

/**
 * @member {String} serving_service_id
 */
DeploymentOut.prototype['serving_service_id'] = undefined;

/**
 * @member {String} serving_host
 */
DeploymentOut.prototype['serving_host'] = undefined;

/**
 * @member {String} model_version_id
 */
DeploymentOut.prototype['model_version_id'] = undefined;

/**
 * @member {String} shadow_model_version_id
 */
DeploymentOut.prototype['shadow_model_version_id'] = undefined;

/**
 * @member {Date} created_at
 */
DeploymentOut.prototype['created_at'] = undefined;

/**
 * @member {Object} labels
 */
DeploymentOut.prototype['labels'] = undefined;

/**
 * @member {Boolean} shadowed
 */
DeploymentOut.prototype['shadowed'] = undefined;






export default DeploymentOut;

