/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ComputeClientTypeEnum from './ComputeClientTypeEnum';

/**
 * The ComputeConnectorOut model module.
 * @module model/ComputeConnectorOut
 * @version 1.0.0
 */
class ComputeConnectorOut {
    /**
     * Constructs a new <code>ComputeConnectorOut</code>.
     * @alias module:model/ComputeConnectorOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param name {String} 
     * @param url {String} 
     * @param clientType {module:model/ComputeClientTypeEnum} 
     * @param enabled {Boolean} 
     */
    constructor(createdAt, updatedAt, id, name, url, clientType, enabled) { 
        
        ComputeConnectorOut.initialize(this, createdAt, updatedAt, id, name, url, clientType, enabled);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, name, url, clientType, enabled) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['name'] = name;
        obj['url'] = url;
        obj['client_type'] = clientType;
        obj['enabled'] = enabled;
    }

    /**
     * Constructs a <code>ComputeConnectorOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ComputeConnectorOut} obj Optional instance to populate.
     * @return {module:model/ComputeConnectorOut} The populated <code>ComputeConnectorOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ComputeConnectorOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('client_type')) {
                obj['client_type'] = ComputeClientTypeEnum.constructFromObject(data['client_type']);
            }
            if (data.hasOwnProperty('enabled')) {
                obj['enabled'] = ApiClient.convertToType(data['enabled'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ComputeConnectorOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ComputeConnectorOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ComputeConnectorOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['url'] && !(typeof data['url'] === 'string' || data['url'] instanceof String)) {
            throw new Error("Expected the field `url` to be a primitive type in the JSON string but got " + data['url']);
        }

        return true;
    }


}

ComputeConnectorOut.RequiredProperties = ["created_at", "updated_at", "id", "name", "url", "client_type", "enabled"];

/**
 * @member {Date} created_at
 */
ComputeConnectorOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ComputeConnectorOut.prototype['updated_at'] = undefined;

/**
 * @member {String} id
 */
ComputeConnectorOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ComputeConnectorOut.prototype['name'] = undefined;

/**
 * @member {String} url
 */
ComputeConnectorOut.prototype['url'] = undefined;

/**
 * @member {module:model/ComputeClientTypeEnum} client_type
 */
ComputeConnectorOut.prototype['client_type'] = undefined;

/**
 * @member {Boolean} enabled
 */
ComputeConnectorOut.prototype['enabled'] = undefined;






export default ComputeConnectorOut;

