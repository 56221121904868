/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GenerateObjectNameContext model module.
 * @module model/GenerateObjectNameContext
 * @version 1.0.0
 */
class GenerateObjectNameContext {
    /**
     * Constructs a new <code>GenerateObjectNameContext</code>.
     * @alias module:model/GenerateObjectNameContext
     */
    constructor() { 
        
        GenerateObjectNameContext.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>GenerateObjectNameContext</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GenerateObjectNameContext} obj Optional instance to populate.
     * @return {module:model/GenerateObjectNameContext} The populated <code>GenerateObjectNameContext</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GenerateObjectNameContext();

            if (data.hasOwnProperty('job_id')) {
                obj['job_id'] = ApiClient.convertToType(data['job_id'], 'String');
            }
            if (data.hasOwnProperty('experiment_id')) {
                obj['experiment_id'] = ApiClient.convertToType(data['experiment_id'], 'String');
            }
            if (data.hasOwnProperty('model_id')) {
                obj['model_id'] = ApiClient.convertToType(data['model_id'], 'String');
            }
            if (data.hasOwnProperty('model_version_id')) {
                obj['model_version_id'] = ApiClient.convertToType(data['model_version_id'], 'String');
            }
            if (data.hasOwnProperty('project_id')) {
                obj['project_id'] = ApiClient.convertToType(data['project_id'], 'String');
            }
            if (data.hasOwnProperty('dataset_version_id')) {
                obj['dataset_version_id'] = ApiClient.convertToType(data['dataset_version_id'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GenerateObjectNameContext</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerateObjectNameContext</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['job_id'] && !(typeof data['job_id'] === 'string' || data['job_id'] instanceof String)) {
            throw new Error("Expected the field `job_id` to be a primitive type in the JSON string but got " + data['job_id']);
        }
        // ensure the json data is a string
        if (data['experiment_id'] && !(typeof data['experiment_id'] === 'string' || data['experiment_id'] instanceof String)) {
            throw new Error("Expected the field `experiment_id` to be a primitive type in the JSON string but got " + data['experiment_id']);
        }
        // ensure the json data is a string
        if (data['model_id'] && !(typeof data['model_id'] === 'string' || data['model_id'] instanceof String)) {
            throw new Error("Expected the field `model_id` to be a primitive type in the JSON string but got " + data['model_id']);
        }
        // ensure the json data is a string
        if (data['model_version_id'] && !(typeof data['model_version_id'] === 'string' || data['model_version_id'] instanceof String)) {
            throw new Error("Expected the field `model_version_id` to be a primitive type in the JSON string but got " + data['model_version_id']);
        }
        // ensure the json data is a string
        if (data['project_id'] && !(typeof data['project_id'] === 'string' || data['project_id'] instanceof String)) {
            throw new Error("Expected the field `project_id` to be a primitive type in the JSON string but got " + data['project_id']);
        }
        // ensure the json data is a string
        if (data['dataset_version_id'] && !(typeof data['dataset_version_id'] === 'string' || data['dataset_version_id'] instanceof String)) {
            throw new Error("Expected the field `dataset_version_id` to be a primitive type in the JSON string but got " + data['dataset_version_id']);
        }

        return true;
    }


}



/**
 * @member {String} job_id
 */
GenerateObjectNameContext.prototype['job_id'] = undefined;

/**
 * @member {String} experiment_id
 */
GenerateObjectNameContext.prototype['experiment_id'] = undefined;

/**
 * @member {String} model_id
 */
GenerateObjectNameContext.prototype['model_id'] = undefined;

/**
 * @member {String} model_version_id
 */
GenerateObjectNameContext.prototype['model_version_id'] = undefined;

/**
 * @member {String} project_id
 */
GenerateObjectNameContext.prototype['project_id'] = undefined;

/**
 * @member {String} dataset_version_id
 */
GenerateObjectNameContext.prototype['dataset_version_id'] = undefined;






export default GenerateObjectNameContext;

