/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorOut from './CollaboratorOut';
import ExtendedOwnerOut from './ExtendedOwnerOut';

/**
 * The ExtendedOrganizationOut model module.
 * @module model/ExtendedOrganizationOut
 * @version 1.0.0
 */
class ExtendedOrganizationOut {
    /**
     * Constructs a new <code>ExtendedOrganizationOut</code>.
     * @alias module:model/ExtendedOrganizationOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param name {String} 
     * @param owner {module:model/ExtendedOwnerOut} 
     * @param collaborators {Array.<module:model/CollaboratorOut>} 
     * @param defaultDatalakeId {String} 
     * @param defaultConnectorId {String} 
     * @param defaultComputeConnectorId {String} 
     */
    constructor(createdAt, updatedAt, id, name, owner, collaborators, defaultDatalakeId, defaultConnectorId, defaultComputeConnectorId) { 
        
        ExtendedOrganizationOut.initialize(this, createdAt, updatedAt, id, name, owner, collaborators, defaultDatalakeId, defaultConnectorId, defaultComputeConnectorId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, name, owner, collaborators, defaultDatalakeId, defaultConnectorId, defaultComputeConnectorId) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['name'] = name;
        obj['owner'] = owner;
        obj['collaborators'] = collaborators;
        obj['default_datalake_id'] = defaultDatalakeId;
        obj['default_connector_id'] = defaultConnectorId;
        obj['default_compute_connector_id'] = defaultComputeConnectorId;
    }

    /**
     * Constructs a <code>ExtendedOrganizationOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedOrganizationOut} obj Optional instance to populate.
     * @return {module:model/ExtendedOrganizationOut} The populated <code>ExtendedOrganizationOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedOrganizationOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('owner')) {
                obj['owner'] = ExtendedOwnerOut.constructFromObject(data['owner']);
            }
            if (data.hasOwnProperty('collaborators')) {
                obj['collaborators'] = ApiClient.convertToType(data['collaborators'], [CollaboratorOut]);
            }
            if (data.hasOwnProperty('default_datalake_id')) {
                obj['default_datalake_id'] = ApiClient.convertToType(data['default_datalake_id'], 'String');
            }
            if (data.hasOwnProperty('default_connector_id')) {
                obj['default_connector_id'] = ApiClient.convertToType(data['default_connector_id'], 'String');
            }
            if (data.hasOwnProperty('default_compute_connector_id')) {
                obj['default_compute_connector_id'] = ApiClient.convertToType(data['default_compute_connector_id'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedOrganizationOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedOrganizationOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedOrganizationOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // validate the optional field `owner`
        if (data['owner']) { // data not null
          ExtendedOwnerOut.validateJSON(data['owner']);
        }
        if (data['collaborators']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['collaborators'])) {
                throw new Error("Expected the field `collaborators` to be an array in the JSON data but got " + data['collaborators']);
            }
            // validate the optional field `collaborators` (array)
            for (const item of data['collaborators']) {
                CollaboratorOut.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['default_datalake_id'] && !(typeof data['default_datalake_id'] === 'string' || data['default_datalake_id'] instanceof String)) {
            throw new Error("Expected the field `default_datalake_id` to be a primitive type in the JSON string but got " + data['default_datalake_id']);
        }
        // ensure the json data is a string
        if (data['default_connector_id'] && !(typeof data['default_connector_id'] === 'string' || data['default_connector_id'] instanceof String)) {
            throw new Error("Expected the field `default_connector_id` to be a primitive type in the JSON string but got " + data['default_connector_id']);
        }
        // ensure the json data is a string
        if (data['default_compute_connector_id'] && !(typeof data['default_compute_connector_id'] === 'string' || data['default_compute_connector_id'] instanceof String)) {
            throw new Error("Expected the field `default_compute_connector_id` to be a primitive type in the JSON string but got " + data['default_compute_connector_id']);
        }

        return true;
    }


}

ExtendedOrganizationOut.RequiredProperties = ["created_at", "updated_at", "id", "name", "owner", "collaborators", "default_datalake_id", "default_connector_id", "default_compute_connector_id"];

/**
 * @member {Date} created_at
 */
ExtendedOrganizationOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedOrganizationOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedOrganizationOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedOrganizationOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ExtendedOrganizationOut.prototype['name'] = undefined;

/**
 * @member {module:model/ExtendedOwnerOut} owner
 */
ExtendedOrganizationOut.prototype['owner'] = undefined;

/**
 * @member {Array.<module:model/CollaboratorOut>} collaborators
 */
ExtendedOrganizationOut.prototype['collaborators'] = undefined;

/**
 * @member {String} default_datalake_id
 */
ExtendedOrganizationOut.prototype['default_datalake_id'] = undefined;

/**
 * @member {String} default_connector_id
 */
ExtendedOrganizationOut.prototype['default_connector_id'] = undefined;

/**
 * @member {String} default_compute_connector_id
 */
ExtendedOrganizationOut.prototype['default_compute_connector_id'] = undefined;






export default ExtendedOrganizationOut;

