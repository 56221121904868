/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AnnotationCampaignJobOut from './AnnotationCampaignJobOut';
import DatalakeJobOut from './DatalakeJobOut';
import DatasetVersionJobOut from './DatasetVersionJobOut';
import DatasetVersionProcessingJobOut from './DatasetVersionProcessingJobOut';
import DeploymentJobOut from './DeploymentJobOut';
import ExperimentJobOut from './ExperimentJobOut';
import ExtendedUserOut from './ExtendedUserOut';
import JobRunOut from './JobRunOut';
import JobRunStatus from './JobRunStatus';
import JobType from './JobType';

/**
 * The ExtendedJobOut model module.
 * @module model/ExtendedJobOut
 * @version 1.0.0
 */
class ExtendedJobOut {
    /**
     * Constructs a new <code>ExtendedJobOut</code>.
     * @alias module:model/ExtendedJobOut
     * @param id {String} 
     * @param organizationId {String} 
     * @param type {module:model/JobType} 
     * @param createdAt {Date} 
     * @param createdBy {module:model/ExtendedUserOut} 
     * @param lastUpdated {String} 
     * @param runs {Array.<module:model/JobRunOut>} 
     * @param status {module:model/JobRunStatus} 
     * @param datasetVersionProcessingJob {module:model/DatasetVersionProcessingJobOut} 
     * @param annotationCampaignJob {module:model/AnnotationCampaignJobOut} 
     * @param datalakeJob {module:model/DatalakeJobOut} 
     * @param datasetVersionJob {module:model/DatasetVersionJobOut} 
     * @param experimentJob {module:model/ExperimentJobOut} 
     * @param deploymentJob {module:model/DeploymentJobOut} 
     */
    constructor(id, organizationId, type, createdAt, createdBy, lastUpdated, runs, status, datasetVersionProcessingJob, annotationCampaignJob, datalakeJob, datasetVersionJob, experimentJob, deploymentJob) { 
        
        ExtendedJobOut.initialize(this, id, organizationId, type, createdAt, createdBy, lastUpdated, runs, status, datasetVersionProcessingJob, annotationCampaignJob, datalakeJob, datasetVersionJob, experimentJob, deploymentJob);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, organizationId, type, createdAt, createdBy, lastUpdated, runs, status, datasetVersionProcessingJob, annotationCampaignJob, datalakeJob, datasetVersionJob, experimentJob, deploymentJob) { 
        obj['id'] = id;
        obj['organization_id'] = organizationId;
        obj['type'] = type;
        obj['created_at'] = createdAt;
        obj['created_by'] = createdBy;
        obj['last_updated'] = lastUpdated;
        obj['runs'] = runs;
        obj['status'] = status;
        obj['dataset_version_processing_job'] = datasetVersionProcessingJob;
        obj['annotation_campaign_job'] = annotationCampaignJob;
        obj['datalake_job'] = datalakeJob;
        obj['dataset_version_job'] = datasetVersionJob;
        obj['experiment_job'] = experimentJob;
        obj['deployment_job'] = deploymentJob;
    }

    /**
     * Constructs a <code>ExtendedJobOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedJobOut} obj Optional instance to populate.
     * @return {module:model/ExtendedJobOut} The populated <code>ExtendedJobOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedJobOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('organization_id')) {
                obj['organization_id'] = ApiClient.convertToType(data['organization_id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = JobType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ExtendedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('last_updated')) {
                obj['last_updated'] = ApiClient.convertToType(data['last_updated'], 'String');
            }
            if (data.hasOwnProperty('runs')) {
                obj['runs'] = ApiClient.convertToType(data['runs'], [JobRunOut]);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = JobRunStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('dataset_version_processing_job')) {
                obj['dataset_version_processing_job'] = DatasetVersionProcessingJobOut.constructFromObject(data['dataset_version_processing_job']);
            }
            if (data.hasOwnProperty('annotation_campaign_job')) {
                obj['annotation_campaign_job'] = AnnotationCampaignJobOut.constructFromObject(data['annotation_campaign_job']);
            }
            if (data.hasOwnProperty('datalake_job')) {
                obj['datalake_job'] = DatalakeJobOut.constructFromObject(data['datalake_job']);
            }
            if (data.hasOwnProperty('dataset_version_job')) {
                obj['dataset_version_job'] = DatasetVersionJobOut.constructFromObject(data['dataset_version_job']);
            }
            if (data.hasOwnProperty('experiment_job')) {
                obj['experiment_job'] = ExperimentJobOut.constructFromObject(data['experiment_job']);
            }
            if (data.hasOwnProperty('deployment_job')) {
                obj['deployment_job'] = DeploymentJobOut.constructFromObject(data['deployment_job']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedJobOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedJobOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedJobOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['organization_id'] && !(typeof data['organization_id'] === 'string' || data['organization_id'] instanceof String)) {
            throw new Error("Expected the field `organization_id` to be a primitive type in the JSON string but got " + data['organization_id']);
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          ExtendedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['last_updated'] && !(typeof data['last_updated'] === 'string' || data['last_updated'] instanceof String)) {
            throw new Error("Expected the field `last_updated` to be a primitive type in the JSON string but got " + data['last_updated']);
        }
        if (data['runs']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['runs'])) {
                throw new Error("Expected the field `runs` to be an array in the JSON data but got " + data['runs']);
            }
            // validate the optional field `runs` (array)
            for (const item of data['runs']) {
                JobRunOut.validateJSON(item);
            };
        }
        // validate the optional field `dataset_version_processing_job`
        if (data['dataset_version_processing_job']) { // data not null
          DatasetVersionProcessingJobOut.validateJSON(data['dataset_version_processing_job']);
        }
        // validate the optional field `annotation_campaign_job`
        if (data['annotation_campaign_job']) { // data not null
          AnnotationCampaignJobOut.validateJSON(data['annotation_campaign_job']);
        }
        // validate the optional field `datalake_job`
        if (data['datalake_job']) { // data not null
          DatalakeJobOut.validateJSON(data['datalake_job']);
        }
        // validate the optional field `dataset_version_job`
        if (data['dataset_version_job']) { // data not null
          DatasetVersionJobOut.validateJSON(data['dataset_version_job']);
        }
        // validate the optional field `experiment_job`
        if (data['experiment_job']) { // data not null
          ExperimentJobOut.validateJSON(data['experiment_job']);
        }
        // validate the optional field `deployment_job`
        if (data['deployment_job']) { // data not null
          DeploymentJobOut.validateJSON(data['deployment_job']);
        }

        return true;
    }


}

ExtendedJobOut.RequiredProperties = ["id", "organization_id", "type", "created_at", "created_by", "last_updated", "runs", "status", "dataset_version_processing_job", "annotation_campaign_job", "datalake_job", "dataset_version_job", "experiment_job", "deployment_job"];

/**
 * @member {String} id
 */
ExtendedJobOut.prototype['id'] = undefined;

/**
 * @member {String} organization_id
 */
ExtendedJobOut.prototype['organization_id'] = undefined;

/**
 * @member {module:model/JobType} type
 */
ExtendedJobOut.prototype['type'] = undefined;

/**
 * @member {Date} created_at
 */
ExtendedJobOut.prototype['created_at'] = undefined;

/**
 * @member {module:model/ExtendedUserOut} created_by
 */
ExtendedJobOut.prototype['created_by'] = undefined;

/**
 * @member {String} last_updated
 */
ExtendedJobOut.prototype['last_updated'] = undefined;

/**
 * @member {Array.<module:model/JobRunOut>} runs
 */
ExtendedJobOut.prototype['runs'] = undefined;

/**
 * @member {module:model/JobRunStatus} status
 */
ExtendedJobOut.prototype['status'] = undefined;

/**
 * @member {module:model/DatasetVersionProcessingJobOut} dataset_version_processing_job
 */
ExtendedJobOut.prototype['dataset_version_processing_job'] = undefined;

/**
 * @member {module:model/AnnotationCampaignJobOut} annotation_campaign_job
 */
ExtendedJobOut.prototype['annotation_campaign_job'] = undefined;

/**
 * @member {module:model/DatalakeJobOut} datalake_job
 */
ExtendedJobOut.prototype['datalake_job'] = undefined;

/**
 * @member {module:model/DatasetVersionJobOut} dataset_version_job
 */
ExtendedJobOut.prototype['dataset_version_job'] = undefined;

/**
 * @member {module:model/ExperimentJobOut} experiment_job
 */
ExtendedJobOut.prototype['experiment_job'] = undefined;

/**
 * @member {module:model/DeploymentJobOut} deployment_job
 */
ExtendedJobOut.prototype['deployment_job'] = undefined;






export default ExtendedJobOut;

