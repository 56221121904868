/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DatasetVersionProcessingConstraints from './DatasetVersionProcessingConstraints';
import ProcessingType from './ProcessingType';

/**
 * The ProcessingIn model module.
 * @module model/ProcessingIn
 * @version 1.0.0
 */
class ProcessingIn {
    /**
     * Constructs a new <code>ProcessingIn</code>.
     * @alias module:model/ProcessingIn
     * @param dockerImage {String} 
     * @param dockerTag {String} 
     * @param name {String} 
     * @param type {module:model/ProcessingType} 
     * @param defaultCpu {Number} 
     * @param defaultGpu {Number} 
     */
    constructor(dockerImage, dockerTag, name, type, defaultCpu, defaultGpu) { 
        
        ProcessingIn.initialize(this, dockerImage, dockerTag, name, type, defaultCpu, defaultGpu);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, dockerImage, dockerTag, name, type, defaultCpu, defaultGpu) { 
        obj['docker_image'] = dockerImage;
        obj['docker_tag'] = dockerTag;
        obj['name'] = name;
        obj['type'] = type;
        obj['default_cpu'] = defaultCpu;
        obj['default_gpu'] = defaultGpu;
    }

    /**
     * Constructs a <code>ProcessingIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProcessingIn} obj Optional instance to populate.
     * @return {module:model/ProcessingIn} The populated <code>ProcessingIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProcessingIn();

            if (data.hasOwnProperty('docker_image')) {
                obj['docker_image'] = ApiClient.convertToType(data['docker_image'], 'String');
            }
            if (data.hasOwnProperty('docker_tag')) {
                obj['docker_tag'] = ApiClient.convertToType(data['docker_tag'], 'String');
            }
            if (data.hasOwnProperty('docker_registry')) {
                obj['docker_registry'] = ApiClient.convertToType(data['docker_registry'], 'String');
            }
            if (data.hasOwnProperty('docker_flags')) {
                obj['docker_flags'] = ApiClient.convertToType(data['docker_flags'], ['String']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail_object_name')) {
                obj['thumbnail_object_name'] = ApiClient.convertToType(data['thumbnail_object_name'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ProcessingType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('default_parameters')) {
                obj['default_parameters'] = ApiClient.convertToType(data['default_parameters'], Object);
            }
            if (data.hasOwnProperty('default_cpu')) {
                obj['default_cpu'] = ApiClient.convertToType(data['default_cpu'], 'Number');
            }
            if (data.hasOwnProperty('default_gpu')) {
                obj['default_gpu'] = ApiClient.convertToType(data['default_gpu'], 'Number');
            }
            if (data.hasOwnProperty('dataset_version_constraints')) {
                obj['dataset_version_constraints'] = DatasetVersionProcessingConstraints.constructFromObject(data['dataset_version_constraints']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProcessingIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProcessingIn</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ProcessingIn.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['docker_image'] && !(typeof data['docker_image'] === 'string' || data['docker_image'] instanceof String)) {
            throw new Error("Expected the field `docker_image` to be a primitive type in the JSON string but got " + data['docker_image']);
        }
        // ensure the json data is a string
        if (data['docker_tag'] && !(typeof data['docker_tag'] === 'string' || data['docker_tag'] instanceof String)) {
            throw new Error("Expected the field `docker_tag` to be a primitive type in the JSON string but got " + data['docker_tag']);
        }
        // ensure the json data is a string
        if (data['docker_registry'] && !(typeof data['docker_registry'] === 'string' || data['docker_registry'] instanceof String)) {
            throw new Error("Expected the field `docker_registry` to be a primitive type in the JSON string but got " + data['docker_registry']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['docker_flags'])) {
            throw new Error("Expected the field `docker_flags` to be an array in the JSON data but got " + data['docker_flags']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['thumbnail_object_name'] && !(typeof data['thumbnail_object_name'] === 'string' || data['thumbnail_object_name'] instanceof String)) {
            throw new Error("Expected the field `thumbnail_object_name` to be a primitive type in the JSON string but got " + data['thumbnail_object_name']);
        }
        // validate the optional field `dataset_version_constraints`
        if (data['dataset_version_constraints']) { // data not null
          DatasetVersionProcessingConstraints.validateJSON(data['dataset_version_constraints']);
        }

        return true;
    }


}

ProcessingIn.RequiredProperties = ["docker_image", "docker_tag", "name", "type", "default_cpu", "default_gpu"];

/**
 * @member {String} docker_image
 */
ProcessingIn.prototype['docker_image'] = undefined;

/**
 * @member {String} docker_tag
 */
ProcessingIn.prototype['docker_tag'] = undefined;

/**
 * @member {String} docker_registry
 */
ProcessingIn.prototype['docker_registry'] = undefined;

/**
 * @member {Array.<String>} docker_flags
 */
ProcessingIn.prototype['docker_flags'] = undefined;

/**
 * @member {String} name
 */
ProcessingIn.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ProcessingIn.prototype['description'] = undefined;

/**
 * @member {String} thumbnail_object_name
 */
ProcessingIn.prototype['thumbnail_object_name'] = undefined;

/**
 * @member {module:model/ProcessingType} type
 */
ProcessingIn.prototype['type'] = undefined;

/**
 * @member {Object} default_parameters
 */
ProcessingIn.prototype['default_parameters'] = undefined;

/**
 * @member {Number} default_cpu
 */
ProcessingIn.prototype['default_cpu'] = undefined;

/**
 * @member {Number} default_gpu
 */
ProcessingIn.prototype['default_gpu'] = undefined;

/**
 * @member {module:model/DatasetVersionProcessingConstraints} dataset_version_constraints
 */
ProcessingIn.prototype['dataset_version_constraints'] = undefined;






export default ProcessingIn;

