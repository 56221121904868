/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorRole from './CollaboratorRole';
import ContributorRole from './ContributorRole';
import DatalakePermissionnerRole from './DatalakePermissionnerRole';
import DeploymentPermissionnerRole from './DeploymentPermissionnerRole';
import ModelPermissionnerRole from './ModelPermissionnerRole';
import WorkerRole from './WorkerRole';

/**
 * The CollaboratorPermissionsUpdateIn model module.
 * @module model/CollaboratorPermissionsUpdateIn
 * @version 1.0.0
 */
class CollaboratorPermissionsUpdateIn {
    /**
     * Constructs a new <code>CollaboratorPermissionsUpdateIn</code>.
     * @alias module:model/CollaboratorPermissionsUpdateIn
     */
    constructor() { 
        
        CollaboratorPermissionsUpdateIn.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CollaboratorPermissionsUpdateIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CollaboratorPermissionsUpdateIn} obj Optional instance to populate.
     * @return {module:model/CollaboratorPermissionsUpdateIn} The populated <code>CollaboratorPermissionsUpdateIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CollaboratorPermissionsUpdateIn();

            if (data.hasOwnProperty('assigned_role')) {
                obj['assigned_role'] = CollaboratorRole.constructFromObject(data['assigned_role']);
            }
            if (data.hasOwnProperty('default_datalake_permissionner_role')) {
                obj['default_datalake_permissionner_role'] = DatalakePermissionnerRole.constructFromObject(data['default_datalake_permissionner_role']);
            }
            if (data.hasOwnProperty('default_contributor_role')) {
                obj['default_contributor_role'] = ContributorRole.constructFromObject(data['default_contributor_role']);
            }
            if (data.hasOwnProperty('default_worker_role')) {
                obj['default_worker_role'] = WorkerRole.constructFromObject(data['default_worker_role']);
            }
            if (data.hasOwnProperty('default_model_permissionner_role')) {
                obj['default_model_permissionner_role'] = ModelPermissionnerRole.constructFromObject(data['default_model_permissionner_role']);
            }
            if (data.hasOwnProperty('default_deployment_permissionner_role')) {
                obj['default_deployment_permissionner_role'] = DeploymentPermissionnerRole.constructFromObject(data['default_deployment_permissionner_role']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CollaboratorPermissionsUpdateIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CollaboratorPermissionsUpdateIn</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {module:model/CollaboratorRole} assigned_role
 */
CollaboratorPermissionsUpdateIn.prototype['assigned_role'] = undefined;

/**
 * @member {module:model/DatalakePermissionnerRole} default_datalake_permissionner_role
 */
CollaboratorPermissionsUpdateIn.prototype['default_datalake_permissionner_role'] = undefined;

/**
 * @member {module:model/ContributorRole} default_contributor_role
 */
CollaboratorPermissionsUpdateIn.prototype['default_contributor_role'] = undefined;

/**
 * @member {module:model/WorkerRole} default_worker_role
 */
CollaboratorPermissionsUpdateIn.prototype['default_worker_role'] = undefined;

/**
 * @member {module:model/ModelPermissionnerRole} default_model_permissionner_role
 */
CollaboratorPermissionsUpdateIn.prototype['default_model_permissionner_role'] = undefined;

/**
 * @member {module:model/DeploymentPermissionnerRole} default_deployment_permissionner_role
 */
CollaboratorPermissionsUpdateIn.prototype['default_deployment_permissionner_role'] = undefined;






export default CollaboratorPermissionsUpdateIn;

