/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DatasetVersionConstraintsGroup from './DatasetVersionConstraintsGroup';

/**
 * The DatasetVersionConstraints model module.
 * @module model/DatasetVersionConstraints
 * @version 1.0.0
 */
class DatasetVersionConstraints {
    /**
     * Constructs a new <code>DatasetVersionConstraints</code>.
     * @alias module:model/DatasetVersionConstraints
     * @param constraintsGroups {Array.<module:model/DatasetVersionConstraintsGroup>} 
     */
    constructor(constraintsGroups) { 
        
        DatasetVersionConstraints.initialize(this, constraintsGroups);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, constraintsGroups) { 
        obj['constraints_groups'] = constraintsGroups;
    }

    /**
     * Constructs a <code>DatasetVersionConstraints</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DatasetVersionConstraints} obj Optional instance to populate.
     * @return {module:model/DatasetVersionConstraints} The populated <code>DatasetVersionConstraints</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DatasetVersionConstraints();

            if (data.hasOwnProperty('required')) {
                obj['required'] = ApiClient.convertToType(data['required'], 'Boolean');
            }
            if (data.hasOwnProperty('constraints_groups')) {
                obj['constraints_groups'] = ApiClient.convertToType(data['constraints_groups'], [DatasetVersionConstraintsGroup]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DatasetVersionConstraints</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DatasetVersionConstraints</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DatasetVersionConstraints.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['constraints_groups']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['constraints_groups'])) {
                throw new Error("Expected the field `constraints_groups` to be an array in the JSON data but got " + data['constraints_groups']);
            }
            // validate the optional field `constraints_groups` (array)
            for (const item of data['constraints_groups']) {
                DatasetVersionConstraintsGroup.validateJSON(item);
            };
        }

        return true;
    }


}

DatasetVersionConstraints.RequiredProperties = ["constraints_groups"];

/**
 * @member {Boolean} required
 * @default false
 */
DatasetVersionConstraints.prototype['required'] = false;

/**
 * @member {Array.<module:model/DatasetVersionConstraintsGroup>} constraints_groups
 */
DatasetVersionConstraints.prototype['constraints_groups'] = undefined;






export default DatasetVersionConstraints;

