/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DeploymentModelVersionOut from './DeploymentModelVersionOut';
import InferenceType from './InferenceType';
import PicturedUserOut from './PicturedUserOut';
import TagOut from './TagOut';

/**
 * The ExtendedDeploymentOut model module.
 * @module model/ExtendedDeploymentOut
 * @version 1.0.0
 */
class ExtendedDeploymentOut {
    /**
     * Constructs a new <code>ExtendedDeploymentOut</code>.
     * @alias module:model/ExtendedDeploymentOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param createdBy {module:model/PicturedUserOut} 
     * @param id {String} 
     * @param name {String} 
     * @param type {module:model/InferenceType} 
     * @param active {Boolean} 
     * @param targetDatalakeId {String} 
     * @param minThreshold {Number} 
     * @param oracleServiceId {String} 
     * @param oracleHost {String} 
     * @param servingServiceId {String} 
     * @param servingHost {String} 
     * @param modelVersionId {String} 
     * @param shadowModelVersionId {String} 
     * @param labels {Object} 
     * @param shadowed {Boolean} 
     * @param tags {Array.<module:model/TagOut>} 
     * @param nbPredictedAssets {Number} 
     * @param activeAlerts {Number} 
     * @param channelName {String} 
     * @param modelVersion {module:model/DeploymentModelVersionOut} 
     * @param shadowModelVersion {module:model/DeploymentModelVersionOut} 
     * @param lastPredictedAssetCreatedAt {Date} 
     * @param cvatIntegrationId {String} 
     */
    constructor(createdAt, updatedAt, createdBy, id, name, type, active, targetDatalakeId, minThreshold, oracleServiceId, oracleHost, servingServiceId, servingHost, modelVersionId, shadowModelVersionId, labels, shadowed, tags, nbPredictedAssets, activeAlerts, channelName, modelVersion, shadowModelVersion, lastPredictedAssetCreatedAt, cvatIntegrationId) { 
        
        ExtendedDeploymentOut.initialize(this, createdAt, updatedAt, createdBy, id, name, type, active, targetDatalakeId, minThreshold, oracleServiceId, oracleHost, servingServiceId, servingHost, modelVersionId, shadowModelVersionId, labels, shadowed, tags, nbPredictedAssets, activeAlerts, channelName, modelVersion, shadowModelVersion, lastPredictedAssetCreatedAt, cvatIntegrationId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, createdBy, id, name, type, active, targetDatalakeId, minThreshold, oracleServiceId, oracleHost, servingServiceId, servingHost, modelVersionId, shadowModelVersionId, labels, shadowed, tags, nbPredictedAssets, activeAlerts, channelName, modelVersion, shadowModelVersion, lastPredictedAssetCreatedAt, cvatIntegrationId) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['created_by'] = createdBy;
        obj['id'] = id;
        obj['name'] = name;
        obj['type'] = type;
        obj['active'] = active;
        obj['target_datalake_id'] = targetDatalakeId;
        obj['min_threshold'] = minThreshold;
        obj['oracle_service_id'] = oracleServiceId;
        obj['oracle_host'] = oracleHost;
        obj['serving_service_id'] = servingServiceId;
        obj['serving_host'] = servingHost;
        obj['model_version_id'] = modelVersionId;
        obj['shadow_model_version_id'] = shadowModelVersionId;
        obj['labels'] = labels;
        obj['shadowed'] = shadowed;
        obj['tags'] = tags;
        obj['nb_predicted_assets'] = nbPredictedAssets;
        obj['active_alerts'] = activeAlerts;
        obj['channel_name'] = channelName;
        obj['model_version'] = modelVersion;
        obj['shadow_model_version'] = shadowModelVersion;
        obj['last_predicted_asset_created_at'] = lastPredictedAssetCreatedAt;
        obj['cvat_integration_id'] = cvatIntegrationId;
    }

    /**
     * Constructs a <code>ExtendedDeploymentOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedDeploymentOut} obj Optional instance to populate.
     * @return {module:model/ExtendedDeploymentOut} The populated <code>ExtendedDeploymentOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedDeploymentOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = PicturedUserOut.constructFromObject(data['created_by']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = InferenceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('target_datalake_id')) {
                obj['target_datalake_id'] = ApiClient.convertToType(data['target_datalake_id'], 'String');
            }
            if (data.hasOwnProperty('min_threshold')) {
                obj['min_threshold'] = ApiClient.convertToType(data['min_threshold'], 'Number');
            }
            if (data.hasOwnProperty('oracle_service_id')) {
                obj['oracle_service_id'] = ApiClient.convertToType(data['oracle_service_id'], 'String');
            }
            if (data.hasOwnProperty('oracle_host')) {
                obj['oracle_host'] = ApiClient.convertToType(data['oracle_host'], 'String');
            }
            if (data.hasOwnProperty('serving_service_id')) {
                obj['serving_service_id'] = ApiClient.convertToType(data['serving_service_id'], 'String');
            }
            if (data.hasOwnProperty('serving_host')) {
                obj['serving_host'] = ApiClient.convertToType(data['serving_host'], 'String');
            }
            if (data.hasOwnProperty('model_version_id')) {
                obj['model_version_id'] = ApiClient.convertToType(data['model_version_id'], 'String');
            }
            if (data.hasOwnProperty('shadow_model_version_id')) {
                obj['shadow_model_version_id'] = ApiClient.convertToType(data['shadow_model_version_id'], 'String');
            }
            if (data.hasOwnProperty('labels')) {
                obj['labels'] = ApiClient.convertToType(data['labels'], Object);
            }
            if (data.hasOwnProperty('shadowed')) {
                obj['shadowed'] = ApiClient.convertToType(data['shadowed'], 'Boolean');
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [TagOut]);
            }
            if (data.hasOwnProperty('nb_predicted_assets')) {
                obj['nb_predicted_assets'] = ApiClient.convertToType(data['nb_predicted_assets'], 'Number');
            }
            if (data.hasOwnProperty('active_alerts')) {
                obj['active_alerts'] = ApiClient.convertToType(data['active_alerts'], 'Number');
            }
            if (data.hasOwnProperty('channel_name')) {
                obj['channel_name'] = ApiClient.convertToType(data['channel_name'], 'String');
            }
            if (data.hasOwnProperty('model_version')) {
                obj['model_version'] = DeploymentModelVersionOut.constructFromObject(data['model_version']);
            }
            if (data.hasOwnProperty('shadow_model_version')) {
                obj['shadow_model_version'] = DeploymentModelVersionOut.constructFromObject(data['shadow_model_version']);
            }
            if (data.hasOwnProperty('last_predicted_asset_created_at')) {
                obj['last_predicted_asset_created_at'] = ApiClient.convertToType(data['last_predicted_asset_created_at'], 'Date');
            }
            if (data.hasOwnProperty('cvat_integration_id')) {
                obj['cvat_integration_id'] = ApiClient.convertToType(data['cvat_integration_id'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedDeploymentOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedDeploymentOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedDeploymentOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `created_by`
        if (data['created_by']) { // data not null
          PicturedUserOut.validateJSON(data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['target_datalake_id'] && !(typeof data['target_datalake_id'] === 'string' || data['target_datalake_id'] instanceof String)) {
            throw new Error("Expected the field `target_datalake_id` to be a primitive type in the JSON string but got " + data['target_datalake_id']);
        }
        // ensure the json data is a string
        if (data['oracle_service_id'] && !(typeof data['oracle_service_id'] === 'string' || data['oracle_service_id'] instanceof String)) {
            throw new Error("Expected the field `oracle_service_id` to be a primitive type in the JSON string but got " + data['oracle_service_id']);
        }
        // ensure the json data is a string
        if (data['oracle_host'] && !(typeof data['oracle_host'] === 'string' || data['oracle_host'] instanceof String)) {
            throw new Error("Expected the field `oracle_host` to be a primitive type in the JSON string but got " + data['oracle_host']);
        }
        // ensure the json data is a string
        if (data['serving_service_id'] && !(typeof data['serving_service_id'] === 'string' || data['serving_service_id'] instanceof String)) {
            throw new Error("Expected the field `serving_service_id` to be a primitive type in the JSON string but got " + data['serving_service_id']);
        }
        // ensure the json data is a string
        if (data['serving_host'] && !(typeof data['serving_host'] === 'string' || data['serving_host'] instanceof String)) {
            throw new Error("Expected the field `serving_host` to be a primitive type in the JSON string but got " + data['serving_host']);
        }
        // ensure the json data is a string
        if (data['model_version_id'] && !(typeof data['model_version_id'] === 'string' || data['model_version_id'] instanceof String)) {
            throw new Error("Expected the field `model_version_id` to be a primitive type in the JSON string but got " + data['model_version_id']);
        }
        // ensure the json data is a string
        if (data['shadow_model_version_id'] && !(typeof data['shadow_model_version_id'] === 'string' || data['shadow_model_version_id'] instanceof String)) {
            throw new Error("Expected the field `shadow_model_version_id` to be a primitive type in the JSON string but got " + data['shadow_model_version_id']);
        }
        if (data['tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tags'])) {
                throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
            }
            // validate the optional field `tags` (array)
            for (const item of data['tags']) {
                TagOut.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['channel_name'] && !(typeof data['channel_name'] === 'string' || data['channel_name'] instanceof String)) {
            throw new Error("Expected the field `channel_name` to be a primitive type in the JSON string but got " + data['channel_name']);
        }
        // validate the optional field `model_version`
        if (data['model_version']) { // data not null
          DeploymentModelVersionOut.validateJSON(data['model_version']);
        }
        // validate the optional field `shadow_model_version`
        if (data['shadow_model_version']) { // data not null
          DeploymentModelVersionOut.validateJSON(data['shadow_model_version']);
        }
        // ensure the json data is a string
        if (data['cvat_integration_id'] && !(typeof data['cvat_integration_id'] === 'string' || data['cvat_integration_id'] instanceof String)) {
            throw new Error("Expected the field `cvat_integration_id` to be a primitive type in the JSON string but got " + data['cvat_integration_id']);
        }

        return true;
    }


}

ExtendedDeploymentOut.RequiredProperties = ["created_at", "updated_at", "created_by", "id", "name", "type", "active", "target_datalake_id", "min_threshold", "oracle_service_id", "oracle_host", "serving_service_id", "serving_host", "model_version_id", "shadow_model_version_id", "labels", "shadowed", "tags", "nb_predicted_assets", "active_alerts", "channel_name", "model_version", "shadow_model_version", "last_predicted_asset_created_at", "cvat_integration_id"];

/**
 * @member {Date} created_at
 */
ExtendedDeploymentOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedDeploymentOut.prototype['updated_at'] = undefined;

/**
 * @member {module:model/PicturedUserOut} created_by
 */
ExtendedDeploymentOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedDeploymentOut.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ExtendedDeploymentOut.prototype['name'] = undefined;

/**
 * @member {module:model/InferenceType} type
 */
ExtendedDeploymentOut.prototype['type'] = undefined;

/**
 * @member {Boolean} active
 */
ExtendedDeploymentOut.prototype['active'] = undefined;

/**
 * @member {String} target_datalake_id
 */
ExtendedDeploymentOut.prototype['target_datalake_id'] = undefined;

/**
 * @member {Number} min_threshold
 */
ExtendedDeploymentOut.prototype['min_threshold'] = undefined;

/**
 * @member {String} oracle_service_id
 */
ExtendedDeploymentOut.prototype['oracle_service_id'] = undefined;

/**
 * @member {String} oracle_host
 */
ExtendedDeploymentOut.prototype['oracle_host'] = undefined;

/**
 * @member {String} serving_service_id
 */
ExtendedDeploymentOut.prototype['serving_service_id'] = undefined;

/**
 * @member {String} serving_host
 */
ExtendedDeploymentOut.prototype['serving_host'] = undefined;

/**
 * @member {String} model_version_id
 */
ExtendedDeploymentOut.prototype['model_version_id'] = undefined;

/**
 * @member {String} shadow_model_version_id
 */
ExtendedDeploymentOut.prototype['shadow_model_version_id'] = undefined;

/**
 * @member {Object} labels
 */
ExtendedDeploymentOut.prototype['labels'] = undefined;

/**
 * @member {Boolean} shadowed
 */
ExtendedDeploymentOut.prototype['shadowed'] = undefined;

/**
 * @member {Array.<module:model/TagOut>} tags
 */
ExtendedDeploymentOut.prototype['tags'] = undefined;

/**
 * @member {Number} nb_predicted_assets
 */
ExtendedDeploymentOut.prototype['nb_predicted_assets'] = undefined;

/**
 * @member {Number} active_alerts
 */
ExtendedDeploymentOut.prototype['active_alerts'] = undefined;

/**
 * @member {String} channel_name
 */
ExtendedDeploymentOut.prototype['channel_name'] = undefined;

/**
 * @member {module:model/DeploymentModelVersionOut} model_version
 */
ExtendedDeploymentOut.prototype['model_version'] = undefined;

/**
 * @member {module:model/DeploymentModelVersionOut} shadow_model_version
 */
ExtendedDeploymentOut.prototype['shadow_model_version'] = undefined;

/**
 * @member {Date} last_predicted_asset_created_at
 */
ExtendedDeploymentOut.prototype['last_predicted_asset_created_at'] = undefined;

/**
 * @member {String} cvat_integration_id
 */
ExtendedDeploymentOut.prototype['cvat_integration_id'] = undefined;






export default ExtendedDeploymentOut;

